import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/Enquiries/enquirySlice";
import { useSelector } from "react-redux";
import { useUpdateTransferDataMutation } from "../../../../store/queries/enquires";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";


const useTranfserForm = ({ refetch }) => {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.enquiry);
  const [transferMember] = useUpdateTransferDataMutation();

  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.showTransferModal = false;
        state.transferNote = "";
      })
    );
  };

  const validation = Yup.object({
    transferNote:
      Yup.string().max(255, "max 255 characters allowed")
      .required("Note is required"),
  });

  const formik = useFormik({
    initialValues: {
      transferNote: "",
    },
    validationSchema: validation,
    onSubmit: (values) => {
      let data = {
        enquiry_id: state?.IDs?.enquiryID,
        reason: values?.transferNote,
        assigned_to: state?.IDs?.staffID,
      };
      transferMember(data).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Transfer success ");
          
          dispatch(
            updateConfig((state) => {
              state.showTransferModal = false;
            })
          );
          refetch();
        } else {
          toast.error("Transfer Failed");
        }
      });
    },
  });

  return { handleClose, formik };
};

export default useTranfserForm;

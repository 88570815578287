import { useFormik } from "formik";
import { useEffect } from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { updatePaymentData } from "../InvoiceTable/api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateConfig } from "../../../store/slices/Invoices/invoicesSlice";

const useEditPaymentForm = (selectedId, closeModal, refetch) => {
  const dispatch = useDispatch();
  const { editData } = useSelector((state) => state.invoices);

  useEffect(() => {
    formik.setFieldValue("total_amount", editData?.data?.total_amount);
    formik.setFieldValue("paid_amount", editData?.data?.paid_amount);
    //eslint-disable-next-line
  }, [editData?.total_amount, editData?.total_amount]);

  const formik = useFormik({
    initialValues: {
      total_amount: "",
      paid_amount: "",
    },
    validationSchema: Yup.object({
      total_amount: Yup.string().required(`Total amount required`),
      paid_amount: Yup.string().required(`Total paid amount required`),
    }),
    onSubmit: (values) => {
      const params = {
        selectedId: selectedId,
        value: {
          total_amount: values?.total_amount,
          paid_amount: values?.paid_amount,
        },
      };
      updatePaymentData(params).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Payment amount updated");
          closeModal();
          refetch();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        } else {
          toast.error(`Something went wrong`);
        }
      });
    },
  });
  return { formik };
};

export default useEditPaymentForm;

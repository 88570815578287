import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useEditForm from "./useEditForm";

const EditForm = () => {
  const { formik, dateStartDateStr, handleStartDateChange, closeModal } =
    useEditForm();
  return (
    <div>
      <div className="input-wrap ">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Module Start Date*
        </label>
        <div className={`pro-w-100 pro-d-flex pro-flex-column`}>
          <Input
            value={
              formik.values.module_start_date === ""
                ? "yyyy-MM-dd"
                : dateStartDateStr
            }
            type={"date"}
            className={`pro-input lg ${
              formik.touched.module_start_date &&
              formik.errors.module_start_date &&
              "error"
            }`}
            onChange={handleStartDateChange}
            onBlur={formik.handleBlur("module_start_date")}
            error={
              formik.touched.module_start_date &&
              formik.errors.module_start_date
            }
            errorMessage={formik.errors.module_start_date}
          />
        </div>
      </div>
      <Input
        label={"Weekly Hours*"}
        id={`weekly_hours`}
        name={`weekly_hours`}
        type="text"
        {...formik.getFieldProps(`weekly_hours`)}
        className={`pro-input lg ${
          formik.touched.weekly_hours && formik.errors.weekly_hours && " error"
        }`}
        error={formik.touched.weekly_hours && formik.errors.weekly_hours}
        errorMessage={formik.errors.weekly_hours}
      />
      <div className="pro-d-flex pro-justify-end pro-pt-4">
        <Button
          className={"pro-btn-link lg "}
          onClick={() => closeModal()}
          type="button"
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default EditForm;

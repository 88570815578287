import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage:1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  transferNote: "",
  is_edit: false,
  IDs: {},
  selectedItemsDetails: {},
};
export const getCourseDataByID = createAsyncThunk(
  "course/show",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/course/show/${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  })
  export const createCourses= createAsyncThunk(
    "v1/course/store",
    async (data, { rejectWithValue }) => {
      const api = await getAxiosInstance();
      try {
        const response = await api.post("/v1/course/store", data);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  
  export const updateCourses = createAsyncThunk(
    "v1/course/update/",
    async ({ id, data }, { rejectWithValue }) => {
      const api = await getAxiosInstance();
      try {
        const response = await api.post(`/v1/course/update/${id}`, data);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
  export const deleteCourses = createAsyncThunk(
    "/v1/faculty/delete",
    async (Id, { rejectWithValue }) => {
      const api = await getAxiosInstance();
      try {
        const response = await api.post(`v1/course/delete/${Id}`);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
const coursesSlice = createSlice({
  name: "courses",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  
  },
  extraReducers: (builder) => {
    builder.addCase(getCourseDataByID.pending, (state) => {
      state.isLoading = true;
      state.selectedItemsDetails = {}
      state.error = null;
    })
    .addCase(getCourseDataByID.fulfilled, (state, action) => {
     
      state.selectedItemsDetails = action.payload.data.data;
    })
    .addCase(getCourseDataByID.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  }
  
});

export const { updateConfig } = coursesSlice.actions;

export default coursesSlice.reducer;

import { getAxiosInstance } from "../../../api";

export const getInvoiceData = async (transactionId) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `v1/invoice/single-transaction-data/${transactionId}`
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const getInvoiceEditData = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/v1/invoice/single-invoice-data/${params}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const updatePaymentData = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/v1/invoice/update-amount/${params?.selectedId}`,
      params?.value
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

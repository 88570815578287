import { useSelector } from "react-redux";
import { useMemo } from "react";

import Roles from "../components/Configure/Roles";

import Appearance from "../components/Configure/Appearance";
import Enquiry from "../components/Enquires/Enquiry";
import Branches from "../components/Branches";
import Courses from "../components/Courses";
import Faculties from "../components/Faculties";
import Sales from "../components/Sales";
import Invoices from "../components/Invoices";
import SubAdmins from "../components/SubAdmin";
import ActionLogs from "../components/Configure/ActionLog";
import StudentsListing from "../components/Students/StudentsListing";
import Email from "../components/Configure/Email";
import Dashboard from "../components/Dashboard";
import Enrolled from "../components/Enroll/Enrolled";
import TimeTable from "../components/TimeTables/TimeTable";

const useRoutes = () => {
  const globalState = useSelector((state) => state.global);

  const EnquiresComponents = [
    {
      component: <Enquiry />,
      permission: "Enquiry",
      path: "enquires",
    },
  ];
  const EnrollComponents = [
    {
      component: <Enrolled />,
      permission: "Enrolled",
      path: "enroll",
    },
  ];

  const DashboardComponent = [
    {
      component: <Dashboard />,
      permission: "Dashboard",
      path: "dashboard",
    },
  ];

  const StudentsComponents = [
    {
      component: <StudentsListing />,
      permission: "Students",
      path: "students",
    },
  ];

  // const BranchesComponents = [
  //   {
  //     component: <Branches />,
  //     permission: "Branches",
  //     path: "branches",
  //   },
  // ];

  const CoursesComponents = [
    {
      component: <Courses />,
      permission: "Courses",
      path: "courses",
    },
  ];
  const FacultyComponents = [
    {
      component: <Faculties />,
      permission: "Faculties",
      path: "faculties",
    },
  ];
  const TimeTableComponents = [
    {
      component: <TimeTable />,
      permission: "Time Table",
      path: "time-table",
    },
  ];
  const SalesComponents = [
    {
      component: <Sales />,
      permission: "Sales",
      path: "sales",
    },
  ];
  // const ActionLogsComponents = [
  //   {
  //     component: <ActionLogs />,
  //     permission: "Action Logs",
  //     path: "action-logs",
  //   },
  // ];
  const InvoicesComponents = [
    {
      component: <Invoices />,
      permission: "Payments",
      path: "payments",
    },
  ];

  // const SubAdminsComponents = [
  //   {
  //     component: <SubAdmins />,
  //     permission: "Sub-admin",
  //     path: "sub-admins",
  //   },
  // ];

  const SettingsComponents = [
    {
      component: <Roles />,
      permission: "User Types",
      path: "roles",
    },
    {
      component: <Email />,
      permission: "Email",
      path: "email",
    },
    // {
    //   component: <Invoices />,
    //   permission: "Invoices",
    //   path: "invoices",
    // },
    {
      component: <ActionLogs />,
      permission: "Action Logs",
      path: "action-logs",
    },
    {
      component: <SubAdmins />,
      permission: "Sub-admin",
      path: "sub-admins",
    },
    {
      component: <Branches />,
      permission: "Branches",
      path: "branches",
    },
    {
      component: <Appearance />,
      permission: "Appearance",
      path: "appearance",
    },
  ];

  const drawerMenuPermission = useMemo(() => {
    let menus = globalState?.dashboard_permissions?.modules?.flatMap?.(
      (menu) => [
        // ...menu.permissions?.map((side_menu) => side_menu.name),
        menu.menu_name,
      ]
    );
    if (!menus) {
      menus = [];
    }
    menus = [...menus, "Appearance", "Dashboard"];
    return menus;

    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  function hasPermission(permission) {
    const user = {
      permissions: drawerMenuPermission,
    };
    return user.permissions?.includes(permission);
  }

  const allowedDashboardComponent = DashboardComponent.filter(
    ({ permission }) => hasPermission(permission)
  );

  const allowedEnquiresComponent = EnquiresComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  const allowedStudenstComponent = StudentsComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  // const allowedBranchesComponent = BranchesComponents.filter(({ permission }) =>
  //   hasPermission(permission)
  // );

  const allowedCoursesComponent = CoursesComponents.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedFaculitiesComponent = FacultyComponents.filter(
    ({ permission }) => hasPermission(permission)
  );

  const allowedInvoicesComponent = InvoicesComponents.filter(({ permission }) =>
    hasPermission(permission)
  );

  // const allowedSubAdminsComponent = SubAdminsComponents.filter(
  //   ({ permission }) => hasPermission(permission)
  // );

  const allowedSettingsComponent = SettingsComponents.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedSalesComponent = SalesComponents.filter(({ permission }) =>
    hasPermission(permission)
  );
  // const allowedActionLogsComponent = ActionLogsComponents.filter(
  //   ({ permission }) => hasPermission(permission)
  // );
  const allowedEnrollComponent = EnrollComponents.filter(({ permission }) =>
    hasPermission(permission)
  );
  const allowedTimeTableComponent = TimeTableComponents.filter(
    ({ permission }) => hasPermission(permission)
  );
  return {
    allowedDashboardComponent,
    allowedEnquiresComponent,
    allowedStudenstComponent,
    allowedSettingsComponent,
    // allowedBranchesComponent,
    // allowedActionLogsComponent,
    allowedFaculitiesComponent,
    allowedCoursesComponent,
    allowedSalesComponent,
    allowedEnrollComponent,
    allowedInvoicesComponent,
    allowedTimeTableComponent,
    // allowedSubAdminsComponent,
  };
};

export default useRoutes;

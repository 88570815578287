import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useMemo, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Style from "./notesCk.module.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-toastify";

const NotesCk = ({
  handleNotesCkResult,
  noteIsLoading,
  selectedItemData,
  notificationOptions,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [isEditorEmpty, setIsEditorEmpty] = useState(true);
  const fileRef = useRef(null);
  const [file, setFile] = useState("");
  const [notify, setNotify] = useState("");
  const handleContentChange = (editorState) => {
    setEditorState(editorState);
    const getContentState = editorState.getCurrentContent();
    setIsEditorEmpty(getContentState.getPlainText().trim() === '');
  };
  const activeUser = useMemo(
    () => localStorage.getItem("USER_DETAILS") ?? [],
    []
  );

 
  const customOptions = {
    blockType: {
      inDropdown: true,
      options: [
        "unordered-list-item",
        { label: "Numbered", style: "ordered-list-item", icon: "1." },
      ],
    },
    block: {
      options: [
        "blockquote",
        "code",
        "header",
        "unordered-list-item",
        "ordered-list-item",
      ],
      className: "custom-block-option",
      dropdownClassName: "custom-block-option-dropdown",
    },
    options: ["inline", "list"],
    inline: {
      options: ["bold", "italic"],
    },

    list: {
      options: ["unordered", "ordered"],
    },
  };
  const handleSave = (e, file) => {
    e.preventDefault();

    const contentState = editorState.getCurrentContent();
    const contentRaw = convertToRaw(contentState);
    const plainText = draftToHtml(contentRaw);
    if (isEditorEmpty) {
      toast.error('Editor content is required');
    } else if (plainText) {
      handleNotesCkResult(plainText, file, notify, activeUser);
    }
  };

  const handleClick = () => {
    fileRef.current.click();
  };

  const handleFile = (e) => {
    setFile(e.target.files);
  };

  return (
    <>
      <Editor
        wrapperClassName={Style.pro_editor_wrapper}
        editorClassName={Style.pro_editor_main}
        toolbarClassName={Style.pro_editor_toolbar}
        editorState={editorState}
        onEditorStateChange={handleContentChange}
        toolbar={customOptions}
        // toolbarCustomButtons={[
        //   <span key="notification-button" className="rdw-option-wrapper notification-button pro-ms-auto">
        //     <span className="material-symbols-outlined x3">notifications</span>
        //   </span>,
        // ]}
        toolbarCustomButtons={[
          <Dropdown className={`${Style.dropdown}`} drop="start">
            <Dropdown.Toggle
              id="profile-dropdown"
              className={Style.dropdown_btn}
              title="Reminder"
            >
              <span
                key="notification-button"
                className="rdw-option-wrapper notification-button pro-m-0"
              >
                <span className="material-symbols-outlined x3">
                  notifications
                </span>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className={Style.dropdown_menu}>
              <Dropdown.ItemText
                className={`pro-fw-normal pro-font-xs pro-pb-3 ${Style.item_text}`}
              >
                Remind me about this
              </Dropdown.ItemText>
              {notificationOptions?.map((item) => {
                return (
                  <Dropdown.Item
                    className={`pro-d-flex pro-items-center  ${
                      item?.value === notify ? "active" : ""
                    }`}
                    onClick={() => setNotify(item?.value)}
                  >
                    <span className={`${Style.dropdown_text} `}>
                      {item?.label}
                    </span>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>,
        ]}
      />
      <div className="pro-d-flex pro-justify-between pro-items-center pro-gap-6 pro-pt-3">
        <div
          onClick={handleClick}
          className={`pro-d-flex pro-items-center pro-flex-grow-1 cursor-pointer`}
        >
          <span className="material-symbols-outlined">attach_file</span>
          {file ? <span>{file?.[0]?.name}</span> : <span>Add files</span>}
        </div>
        <button
          className={`pro-btn pro-btn-primary pro-px-2 pro-py-2 ${
            noteIsLoading ? "loading" : ""
          }`}
          onClick={(e) => handleSave(e, file)}
          disabled={noteIsLoading}
        >
          Add Note
        </button>
      </div>

      <div className="input-wrap">
        <div className="input-drag">
          <input
            type="file"
            accept=".jpeg, .jpg , .png"
            ref={fileRef}
            className={`pro-input ${" error"}`}
            style={{ display: "none" }}
            id="profile"
            name="profile"
            onChange={(e) => handleFile(e)}
          />
        </div>
      </div>
    </>
  );
};

export default NotesCk;

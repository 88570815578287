import Style from "./avatarCardList.module.scss";
import AvatarCard from "../AvatarCard";

const AvatarCardList = ({ staffData }) => {
  return (
    <div className={`pro-d-flex pro-flex-column pro-p-5 ${Style.root}`}>
      <AvatarCard
        name={staffData?.full_name}
        designation={staffData?.user_type_name}
        image={staffData?.image_url}
      />
      <div className={Style.list_wrap}>
        <p>{staffData?.sales?.[0].branch_name}</p>
        
        <p>
          <a href={`tel:${staffData?.sales?.[0]?.user?.mobile_number}`}>
            {`${staffData?.sales?.[0]?.user?.dial_code} ${staffData?.sales?.[0]?.user?.mobile_number}`}
          </a>
        </p>
        <p>
          <a href={`mailto:${staffData?.sales?.[0]?.user?.email}`}>
            {staffData?.sales?.[0]?.user?.email}
          </a>
        </p>
      </div>
    </div>
  );
};

export default AvatarCardList;

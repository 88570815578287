import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import useCreateEmail from "./useCreateEmail";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import Style from "./createEmail.module.scss";

const CreateEmail = ({ closeModal, refetch }) => {
  const {
    formik,
    editorStates,
    selectedItemsDetails,
    handleContentChange,
    optionsData,
    validateEditorContent,
  } = useCreateEmail({ closeModal, refetch });

  return (
    <div className="pro-p-5">
      <div className="pro-input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Email Scenario*
        </label>
        <Select
          id="email_scenario_id"
          isClearable={false}
          placeholder={"Select"}
          classNamePrefix="pro-input"
          className={`pro-input lg } ${
            formik.touched.email_scenario_id &&
            formik.errors.email_scenario_id &&
            " error"
          }`}
          options={optionsData?.data}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={optionsData?.data?.filter(
            (m) => formik.values.email_scenario_id === m?.id
          )}
          onBlur={formik.handleBlur("email_scenario_id")}
          onChange={(value) => {
            formik.setFieldValue("email_scenario_id", value?.id || null);
          }}
        />
        {formik.touched.email_scenario_id &&
          formik.errors.email_scenario_id && (
            <span className="error-text">
              {formik.errors.email_scenario_id}
            </span>
          )}{" "}
      </div>
      <Input
        label={"Name*"}
        id={`name`}
        name={`name`}
        type="text"
        {...formik.getFieldProps(`name`)}
        className={`pro-input lg ${
          formik.touched.name && formik.errors.name && " error"
        }`}
        error={formik.touched.name && formik.errors.name}
        errorMessage={formik.errors.name}
      />
      <Input
        label={"Subject*"}
        id={`subject`}
        name={`subject`}
        type="text"
        {...formik.getFieldProps(`subject`)}
        className={`pro-input lg ${
          formik.touched.subject && formik.errors.subject && " error"
        }`}
        error={formik.touched.subject && formik.errors.subject}
        errorMessage={formik.errors.subject}
      />
      <div className={Style.editor_container}>
        <Editor
          wrapperClassName={Style.pro_editor_wrapper}
          editorClassName={Style.pro_editor_main}
          toolbarClassName={Style.pro_editor_toolbar}
          editorState={formik.values.editorContent}
          // editorState={
          //    editorStates
          // }
          readOnly={selectedItemsDetails === "" ? true : false}
          onEditorStateChange={handleContentChange}
          // toolbarStyle={{ paddingTop: "30px" , marginTop: "30px"}}
          toolbar={{
            options: ["inline", "blockType", "list", "link", "image"],
            blockType: {
              inDropdown: true,
              className: Style.dropdown_wrapper,
              dropdownClassName: Style.dropdown_menu,
            },
          }}
        />
        {formik.touched.editorContent && formik.errors.editorContent && (
          <span className={Style.error_text}>
            {formik.errors.editorContent}
          </span>
        )}{" "}
      </div>
      <div className="pro-d-flex pro-justify-end pro-pt-4">
        <button
          className={"pro-btn-link lg "}
          onClick={() => closeModal()}
          type="button"
        >
          Cancel
        </button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default CreateEmail;

import { Button } from "@wac-ui-dashboard/wac_component_library";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Editor } from "react-draft-wysiwyg";
import Select from "react-select";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { SelectWithIcons } from "./SelectWithIcons";
import Style from "./mailCk.module.scss";
import { useDispatch } from "react-redux";
import { getUserDetails, updateConfig } from "../../../store/slices/Global";
import useObserver from "../../../utils/hooks/useObserver";
import { useSelector } from "react-redux";

const MailCk = ({
  handleMailCkResult,
  handleClose,
  UserDataOptions,
  isMultiSelected,
  setIsMultiSelected,
  mailIsLoading,
}) => {
  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const globalState = useSelector((state) => state.global);
  const activeUser = useMemo(
    () => localStorage.getItem("USER_DETAILS") ?? [],
    []
  );
  const [usersData, setusersData] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [userList, setUserList] = useState([]);
  const fileRef = useRef(null);
  const [file, setFile] = useState("");
  const handleContentChange = (editorState) => {
    setEditorState(editorState);
  };

  // const noOptionsMessage = () =>
  // usersData?.length > 0 ? "No matching options" : "Search for a user...";

  const handleInputChange = (item) => {
    if (item?.length >= 2 && globalState.searchKey !== item) {
      dispatch(
        getUserDetails({
          searchKey: item,
          page: 1,
          clearState: true,
          user_id: activeUser,
        })
      );
    }
  };

  const handleLoadMore = useCallback(() => {
    if (globalState?.searchStatus === "fulfilled") {
      dispatch(
        getUserDetails({
          searchKey: globalState?.searchKey,
          page: globalState?.searchData?.[activeUser]?.current_page + 1,
          user_id: activeUser,
        })
      );
    }

    //eslint-disable-next-line
  }, [dispatch, activeUser, globalState]);

  const { lastElement } = useObserver({
    loading:
      globalState?.searchData?.[activeUser]?.searchStatus !== "fulfilled",
    hasMore:
      globalState?.currentPage *
        globalState?.searchData?.[activeUser]?.current_page <
      globalState?.searchData?.[activeUser]?.total,
    callback: handleLoadMore,
  });

  const handleUserChange = (selectedOptions) => {
    setUserIds(selectedOptions?.map((item) => item.value));

    setIsMultiSelected(selectedOptions?.map((item) => item.value));
    // formik.setFieldValue("user_id", value?.id);
    // setSelectedUser(value?.id);
    // setSkip(() => false);
    // dispatch(
    //   updateConfig((state) => {
    //     state.selectedUserId = value?.id;
    //   })
    // );
  };

  useEffect(() => {
    var temp = [];
    UserDataOptions?.map((element) => {
      temp.push({
        value: element.id,
        label: element.name,
        icon: (
          <img
            src={element.thumbnail_url}
            alt={""}
            width={"500"}
            height={"600"}
          ></img>
        ),
      });
      return element;
    });
    setusersData(temp);
  }, [UserDataOptions]);

  const customOptions = {
    blockType: {
      inDropdown: true,
      options: [
        "unordered-list-item",
        { label: "Numbered", style: "ordered-list-item", icon: "1." },
      ],
    },
    block: {
      options: [
        "blockquote",
        "code",
        "header",
        "unordered-list-item",
        "ordered-list-item",
      ],
      className: "custom-block-option",
      dropdownClassName: "custom-block-option-dropdown",
    },
    options: ["inline", "list"],
    inline: {
      options: ["bold", "italic", "underline"],
    },

    list: {
      options: ["unordered", "ordered"],
    },
  };

  const handleSave = (e, file, userIds) => {
    e.preventDefault();
    const contentState = editorState.getCurrentContent();
    const contentRaw = convertToRaw(contentState);
    const plainText = draftToHtml(contentRaw);
    if (plainText) {
      handleMailCkResult(plainText, file, userIds);
    }
  };

  const handleClick = () => {
    fileRef.current.click();
  };

  const handleFile = (e) => {
    setFile(e.target.files);
  };

  return (
    <>
      <SelectWithIcons
        menuPlacement={"auto"}
        menuPosition="auto"
        options={usersData}
        isMultiSelected={isMultiSelected}
        //  noOptionsMessage={noOptionsMessage}
        multiSelect={true}
        disabled={true}
        // handleInputChange={handleInputChange}
        //  onChange={(staff) => handleUserChange(staff, data)}
        onChange={handleUserChange}
      />
      {/* <Editor
        wrapperClassName={Style.pro_editor_wrapper}
        editorClassName={Style.pro_editor_main}
        toolbarClassName={Style.pro_editor_toolbar}
        editorState={editorState}
        onEditorStateChange={handleContentChange}
        // toolbarStyle={{ paddingTop: "30px" , marginTop: "30px"}}
        toolbar={customOptions}
      /> */}
      <div className="pro-d-flex pro-justify-end pro-items-center pro-gap-6 pro-mt-4">
        {/* <div
          onClick={handleClick}
          className={`pro-d-flex pro-items-center pro-flex-grow-1 cursor-pointer`}
        >
          <span className="material-symbols-outlined">attach_file</span>
          {file ? <span>{file?.[0]?.name}</span> : <span>Attach files</span>}
        </div> */}
        <div className="pro-d-flex pro-gap-4">
          <button className="pro-btn-link" onClick={(e) => handleClose()}>
            Cancel
          </button>
          <button
            className={`pro-btn pro-btn-primary pro-px-2 pro-py-2 ${
              mailIsLoading ? "loading" : ""
            }`}
            onClick={(e) => handleSave(e, file, userIds)}
            disabled={mailIsLoading}
          >
            Send
          </button>
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <div className="input-drag">
          <input
            type="file"
            accept=".jpeg, .jpg , .png"
            ref={fileRef}
            className={`pro-input ${" error"}`}
            style={{ display: "none" }}
            id="profile"
            name="profile"
            onChange={(e) => handleFile(e)}
            multiple
          />
        </div>
      </div>
    </>
  );
};

export default MailCk;

const useTimeSlotEntries = ({ formik, fields, daysList, timeSlotList }) => {
  const handleRemoveField = (index) => {
    const data = formik.values.timetable_entries?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("timetable_entries", data);
  };
  let daysObj = daysList?.filter((item) => item?.value === fields?.day_id);
  let timeSlotObj = timeSlotList?.filter(
    (item) => item?.id === fields?.time_slot_ids
  );
  return { daysObj, timeSlotObj, handleRemoveField };
};

export default useTimeSlotEntries;

import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useTranfserForm from "./useAddPaymentForm";

const AddPaymentForm = ({ refetch }) => {
  const { handleClose, formik } = useTranfserForm({
    refetch,
  });

  return (
    <div>
      <div className="row">
        <div className="col-12 pro-mb-4">
          <div className="input-wrap">
            <Input
              id="receivedAmount"
              name="receivedAmount"
              label="Received Amount*"
              className={`pro-input lg ${
                formik.touched.receivedAmount &&
                formik.errors.receivedAmount &&
                "error"
              }`}
              error={
                formik.touched.receivedAmount && formik.errors.receivedAmount
              }
              errorMessage={formik.errors.receivedAmount}
              {...formik.getFieldProps("receivedAmount")}
            />
          </div>
        </div>
      </div>

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleClose}>
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          onClick={formik?.handleSubmit}
        >
          {true ? (
            <i
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></i>
          ) : (
            ""
          )}
          Add Payment
        </Button>
      </div>
    </div>
  );
};

export default AddPaymentForm;

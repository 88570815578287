import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";

import {
  createEnquiry,
  getStreams,
  updateEnquiry,
} from "../../../../store/slices/Enquiries/enquirySlice";
import {
  createCourses,
  updateConfig,
  updateCourses,
} from "../../../../store/slices/Courses/coursesSlice";
import { useGetBranchDataQuery } from "../../../../store/queries/courses";

const useCouseForm = ({ refetch, closeModal, showCreateModal }) => {
  const dispatch = useDispatch();
  const [addState, setAddState] = useState([1]);
  const { streamData } = useSelector((state) => state.enquiry);
  const { selectedItemsDetails, userData, clearSelection } = useSelector(
    (state) => state.courses
  );
  const profilefileInputRef = useRef(null);

  const [imageCoverPreview, setImageCoverPreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.image_url : ""
  );

  useEffect(() => {
    setImageCoverPreview(selectedItemsDetails?.image_url);
  }, [selectedItemsDetails?.image_url]);

  const { data: branchData = {}, isSuccess: isOptionDatas } =
    useGetBranchDataQuery({});
  useEffect(() => {
    dispatch(getStreams());
  }, []);

  let YupValidationObject = Yup.object().shape({
    name: Yup.string().required("*Required"),
    duration: Yup.string()
      .matches(/^[0-9]*$/, "Duration must contain only numbers")
      .required("*Required"),
    // weekly_hours: Yup.string()
    //   .matches(/^[0-9]*$/, "Weekly Hours must contain only numbers")
    //   .test(
    //     "lessThanDuration",
    //     "Weekly Hours must not be greater than Duration",
    //     function (value) {
    //       const { duration } = this.parent;
    //       if (value && duration) {
    //         return parseInt(value, 10) <= parseInt(duration, 10);
    //       }
    //       return true;
    //     }
    //   )
    //   .required("*Required"),
    description: Yup.string().required("*Required"),
    course_price: Yup.string()
      .matches(/^[0-9]*$/, "Course Price must be a number")
      .required("*Required"),
    stream_id: Yup.string().required("*Required"),
    branch_id: Yup.string().required("*Required"),
    course_modules: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required("*Required"),

        description: Yup.string().nullable(),

        duration: Yup.string()
          .nullable()
          .matches(/^[0-9]*$/, "Duration must be a number"),
      })
    ),
  });
  useEffect(() => {
    if (clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [clearSelection]);

  const formik = useFormik({
    initialValues: {
      name: selectedItemsDetails !== "" ? selectedItemsDetails?.name : "",

      duration:
        selectedItemsDetails !== "" ? selectedItemsDetails?.duration : "",

      description:
        selectedItemsDetails !== "" ? selectedItemsDetails?.description : "",

      course_price:
        selectedItemsDetails !== "" ? selectedItemsDetails?.course_price : "",
      branch_id:
        selectedItemsDetails !== "" ? selectedItemsDetails?.branch_id : "",
      weekly_hours:
        selectedItemsDetails !== "" ? selectedItemsDetails?.weekly_hours : "",
      stream_id:
        selectedItemsDetails !== "" ? selectedItemsDetails?.stream_id : "",
      course_modules:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.course_modules
          : [{ title: "", description: "", duration: "" }],
    },

    validationSchema: YupValidationObject,
    enableReinitialize: true,

    onSubmit: async (values, { resetForm }) => {
      // let courseDetails = values.course_modules;
      const courseDetails = {};

      const filteredCourseDetails = values.course_modules;

      filteredCourseDetails?.forEach((detail, index) => {
        const title = detail?.title;
        const description = detail?.description;
        const duration = detail?.duration;
        courseDetails[`course_modules[${index}][title]`] = title;
        courseDetails[`course_modules[${index}][description]`] = description;
        courseDetails[`course_modules[${index}][duration]`] = duration;
        courseDetails[`course_modules[${index}][id]`] = detail?.id;
      });

      let obj = {
        name: values.name,
        duration: values.duration,
        description: values.description,
        course_price: values.course_price,
        stream_id: values.stream_id,
        branch_id: values.branch_id,
        // weekly_hours: values.weekly_hours,
        ...courseDetails,
      };

      if (values.image?.name) {
        obj.image = values.image;
      }

      delete obj.course_modules;

      let newObj = Object.entries(obj);

      newObj = newObj
        .filter((item) => item[1] !== undefined && item[1] !== null)
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
      let formData = new FormData();

      Object.keys(newObj).forEach((key) => {
        return formData.append(key, newObj[key]);
      });

      if (selectedItemsDetails !== "") {
        dispatch(
          updateCourses({ id: selectedItemsDetails.id, data: formData })
        ).then((response) => {
          if (response?.payload?.status_code === 200) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            toast.success(response?.payload?.message);
          } else if (response?.payload?.status_code === 400) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      } else {
        dispatch(createCourses(formData)).then((response) => {
          if (response?.payload?.status_code === 200) {
            resetForm();
            refetch();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );

            toast.success(response?.payload?.message);
          } else if (response?.payload?.status_code === 400) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });
  const handleCoverImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageCoverPreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };
  const handleRemoveField = (index) => {
    setAddState(addState?.filter((item, itemIndex) => itemIndex !== index));
    const data = formik?.values?.course_modules?.filter(
      (item, itemIndex) => itemIndex !== index
    );
    formik.setFieldValue("course_modules", data);
  };
  const handleAddItemClick = () => {
    setAddState([...addState, +1]);
    let course_modules = [
      ...formik.values.course_modules,
      { title: "", description: "", duration: "" },
    ];
    formik.setFieldValue("course_modules", course_modules);
  };
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };
  const getFieldError = (fieldName) => {
    if (formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }

    return "";
  };

  return {
    formik,
    userData,
    streamData,
    branchData,
    imageCoverPreview,
    profilefileInputRef,
    handleCoverImage,
    handleRemoveField,
    handleAddItemClick,
    getFieldError,
    addState,
    selectedItemsDetails,

    handleCloseModal,
  };
};

export default useCouseForm;

import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}${params?.faculty ? `/${params?.faculty}` : ""}?length=${
          params?.length || "10"
        }&${getParams("search")}page=${params?.page || 1}`
      );
      // ?length=${
      //   params?.length || "10"
      // }&${getParams("search")}page=${params?.page || 1}

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const timeTable = createApi({
  reducerPath: "timeTableApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "TimeTable",
    "TimeSchedule",
    "FacultyList",
    "DaysList",
    "TimeSlotList",
  ],
  endpoints: (builder) => ({
    getTimeTableListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/time-table/list-by-facultyId`,
      }),
      providesTags: ["TimeTable"],
    }),
    getFacultyListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/faculty/fetch-all-faculty`,
      }),
      providesTags: ["FacultyList"],
    }),
    getDaysListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/time-table/days`,
      }),
      providesTags: ["DaysList"],
    }),
    getTimeSlotListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/time-table/time-slots`,
      }),
      providesTags: ["TimeSlotList"],
    }),
    getEditTimeSchedule: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/time-table/view-time-table/${values?.facultyId}/${values?.dayId}`,
      }),
      providesTags: [`TimeSchedule`],
    }),
  }),
});

export const {
  useGetTimeTableListDataQuery,
  useGetDaysListDataQuery,
  useGetTimeSlotListDataQuery,
  useGetFacultyListDataQuery,
  useGetEditTimeScheduleQuery,
} = timeTable;

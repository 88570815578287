import { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { getFormatedDate } from "../../../utils/functions/table";
import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useGetActionLogDataQuery } from "../../../store/queries/actionLogs";
import { updateConfig } from "../../../store/slices/ActionLogs/actionLogsSlice";

const useActionLog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuState = useSelector((state) => state.actionLogs);

    
  const [selectedId, setSelectedId] = useState([]);
  const [skip, setSkip] = useState(true);

  // const { showCreateModal } = useSelector(
  //   (state) => state.actionLogs
  // );
  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [showImageModal, setShowImageModal] = useState(false);

  const [imageData, setImageData] = useState("");

  const {
    data: mainData = {},
    isFetching,
    isSuccess,
    isLoading,
    refetch,
  } = useGetActionLogDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: menuState.search,
    start: menuState.startDate,
    end: menuState.endDate,
    length: menuState.currentPageSize,
    page: menuState.currentPage,
  });

  // useEffect(() => {
  //   if (
  //     Object.keys(menuState?.queryData ?? {})?.length === 0 &&
  //     activeFilter === 2
  //   ) {
  //     dispatch(
  //       getEnquiryScrollListData({
  //         sort_by: menuState.sortBy,
  //         sort_order: menuState.sortOrder,
  //         search: menuState.search,
  //         start: menuState.startDate,
  //         end: menuState.endDate,
  //         length: menuState.currentPageSize,
  //         page: menuState.scrollPage,
  //       })
  //     );
  //   }

  //   //eslint-disable-next-line
  // }, [activeFilter]);

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);

  // const { data: enquiryViewData = {} } = useGetEnquiryViewDataQuery(
  //   {
  //     digital_enquiry_id: selectedId,
  //     page: 1,
  //   },
  //   { skip }
  // );

  // const { data: optionsData = {} } = useGetMemberCreationFormDataQuery({});

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  // const [updateEnquiryFields] = useUpdateEnquiryListFieldsDataMutation();
  // const [updateEnquiryStatus] = useUpdateEnquiryListStatusMutation();

  // useEffect(() => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.selectedItemsDetails = enquiryViewData?.data ?? "";
  //     })
  //   );

  //   // eslint-disable-next-line
  // }, [enquiryViewData]);

  const hasCreatePermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );
    return permission?.[0]?.can_create ?? 0;
  }, [mainData]);
  const hasEditPermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit ?? 0;
  }, [mainData]);
  const hasViewPermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view")
    );
    return permission?.[0]?.can_view ?? 0;
  }, [mainData]);
  const hasDeletePermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );
    return permission?.[0]?.can_delete ?? 0;
  }, [mainData]);

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handleEditAction = (data) => {
    setSkip(() => false);
    setSelectedId(data?.[0]);

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const handleEditClick = () => {
    // dispatch(
    //   updateConfig((state) => {
    //     state.selectedItemsDetails = enquiryViewData?.data ?? "";
    //   })
    // );
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      log_type: (feild, data) => (
        <p className="pro-mb-0">{data[feild]?.title ?? ""}</p>
      ),
      user: (feild, data) => (
        <p className="pro-mb-0">{data[feild]?.full_name ?? ""}</p>
      ),
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };

  // const handleViewImage = (data) => {
  //   setShowImageModal(() => true);
  //   setImageData(data?.cover_photo?.url);
  // };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = null;
        state.endDate = null;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.invoiceLoading = false;
      })
    );
  };

  const handleDashboardRedirect = (filter) => {
    navigate("/payment/mainData");
  };

  

  return {
    mainData,
    menuState,
    currentPage: menuState.currentPage,
    // showCreateModal,
    hasCreatePermission,
    isFetching,
    isLoading,

    date,
    // optionsData,
    showEditModal,
    tableFields,
    paginationOptions,
    actionOptions,
    // enquiryViewData,
    showImageModal,
    imageData,
    hasEditPermission,
    hasDeletePermission,
        handleEditAction,
    closeImageModal,
    handleDashboardRedirect,
    handleActionChange,
    refetch,
    // updateEnquiryFields,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleClearClick,
    imageData,
  };
};

export default useActionLog;

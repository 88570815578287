import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  showCreateModal: false,
  showAssignModal: false,
  showEditModal: false,
  selectedId: "",
  editSelectedId: "",
  courseCurrentPage: 1,
  paymentCurrentPage: 1,
  courseCurrentPageSize: 10,
  paymentCurrentPageSize: 10,
  clearSelection: false,
  showCourseCreateModal: false,
  scrollStatusPage: {},
  editCourseData: {},
  activeFilter: 1,
  queryData: {},
  queryStatus: "idle",
  courseSortBy: "",
  paymentSortBy: "",
  courseSortOrder: "desc",
  paymentSortOrder: "desc",
};
export const courseAssign = createAsyncThunk(
  "v1/student/assign-new-course/",
  async ({ student_id, data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/v1/student/assign-new-course/${student_id}`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getEditCourseData = createAsyncThunk(
  "student/show-module-details",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/v1/student/show-module-details/${params}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCourseScrollListData = createAsyncThunk(
  "enquiry/getCourseScrollListData",
  async (params, { rejectWithValue }) => {
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/v1/student/student-course/${params.student_id}?${getParams(
          "start_date"
        )}${getParams("end_date")}${getParams("filter")}${getParams(
          "status"
        )}${getParams("sort_by")}sort_order=${
          params?.sort_order || "desc"
        }&length=${params?.length || "10"}&${getParams("search")}page=${
          params?.page || 1
        }`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const studentsProfileSlice = createSlice({
  name: "studentsProfile",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCourseScrollListData.pending, (state, action) => {
      state.queryStatus = "pending";
    });
    builder.addCase(getCourseScrollListData.fulfilled, (state, action) => {
      if (state.queryData?.data && !action.meta?.arg?.clearState) {
        if (action?.payload?.data?.data?.rows?.Student) {
          state.queryData = {
            ...action?.payload?.data?.data?.rows,
            queryStatus: "fulfilled",
            course_modules: [
              ...state.queryData?.data,
              ...action?.payload?.data?.data?.rows?.Student,
            ],
          };
        } else {
          state.queryData = {
            ...action?.payload?.data?.data?.rows,
            queryStatus: "fulfilled",
            course_modules: [...state.queryData?.data],
          };
        }
      } else {
        state.queryData = {
          ...action?.payload?.data?.data?.rows,
          queryStatus: "fulfilled",
          page: 1,
        };
      }
      state.queryStatus = "fulfilled";
    });
    builder.addCase(getCourseScrollListData.rejected, (state, action) => {
      state.queryStatus = "failed";
    });
    builder
      .addCase(getEditCourseData.pending, (state) => {
        state.isLoading = true;
        state.editCourseData = {};
        state.error = null;
      })
      .addCase(getEditCourseData.fulfilled, (state, action) => {
        state.editCourseData = action.payload.data.data;
      })
      .addCase(getEditCourseData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig } = studentsProfileSlice.actions;

export default studentsProfileSlice.reducer;

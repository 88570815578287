import { HeadingGroup, Image } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Assets from "../../../assets/Assets";
import useTheme from "./useTheme";
import Style from "./appearance.module.scss";
import useDrawerTheme from "./useDrawerTheme";

export default function Appearance() {
  const { handleThemeChange, theme } = useTheme("light");
  const { handleDrawerThemeChange, sideMenu } = useDrawerTheme("expanded");

  return (
    <div className={`col-12 pro-p-5`}>
      <div className={`row`}>
        <div className={`col-12 pro-mb-5`}>
          <HeadingGroup title={`Appearance`} />
        </div>
        <div className={`col-12 pro-mt-4`}>
          <div className={`row`}>
            <div className={`col-12 col-lg-3 pro-pt-5`}>
              <h6 className="pro-ttl h6 pro-mb-1">Theme</h6>
              <p className="pro-fw-regular pro-mb-0">Choose your theme</p>
            </div>
            <div className={`col-12 col-lg-9 pro-my-5`}>
              <div className="row">
                <div className="col-6 col-sm-4 col-lg-2">
                  <div
                    className={`pro-w-100 ${Style.root} ${
                      theme === "light" ? Style.active : ""
                    }`}
                    onClick={() => {
                      handleThemeChange("light");
                    }}
                  >
                    <Image src={Assets.THEME_LIGHT} width={180} height={118} />
                    <p className="pro-font-sm pro-fw-medium pro-mb-0 pro-mt-2">
                      Light
                    </p>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-lg-2">
                  <div
                    className={`pro-w-100 ${Style.root} ${
                      theme === "dark" ? Style.active : ""
                    }`}
                    onClick={() => {
                      handleThemeChange("dark");
                    }}
                  >
                    <Image src={Assets.THEME_DARK} width={180} height={118} />
                    <p className="pro-font-sm pro-fw-medium pro-mb-0 pro-mt-2">
                      Dark
                    </p>
                  </div>
                </div>
                {/* <div className="col-6 col-sm-4 col-lg-2">
                  <div
                    className={`pro-w-100 ${Style.root}`}
                    onClick={() => {
                      handleThemeChange("black");
                    }}
                  >
                    <Image src={Assets.THEME_BLACK} width={180} height={118} />
                    <p className="pro-font-sm pro-fw-medium pro-mb-0 pro-mt-2">
                      Black
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <hr className={`pro-my-4`} />
          {/* <div className={`row`}>
            <div className={`col-12 col-lg-3 pro-pt-5`}>
              <h6 className="pro-ttl h6 pro-mb-1">Table view</h6>
              <p className="pro-fw-regular pro-mb-0">
                Duis aute irure reprehenderit
              </p>
            </div>
            <div className={`col-12 col-lg-9 pro-my-5`}>
              <div className="row">
                <div className="col-6 col-sm-4 col-lg-2">
                  <div
                    className={`pro-w-100 ${Style.root}`}
                    onClick={() => {
                      handleThemeChange("compact");
                    }}
                  >
                    <Image
                      src={Assets.THEME_COMPACT}
                      width={180}
                      height={118}
                    />
                    <p className="pro-font-sm pro-fw-medium pro-mb-0 pro-mt-2">
                      Compact
                    </p>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-lg-2">
                  <div
                    className={`pro-w-100 ${Style.root}`}
                    onClick={() => {
                      handleThemeChange("detailed");
                    }}
                  >
                    <Image
                      src={Assets.THEME_DETAILED}
                      width={180}
                      height={118}
                    />
                    <p className="pro-font-sm pro-fw-medium pro-mb-0 pro-mt-2">
                      Detailed
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className={`pro-my-4`} /> */}
          <div className={`row`}>
            <div className={`col-12 col-lg-3 pro-pt-5`}>
              <h6 className="pro-ttl h6 pro-mb-1">Side menu</h6>
              <p className="pro-fw-regular pro-mb-0">
                Choose side menu appearance
              </p>
            </div>
            <div className={`col-12 col-lg-9 pro-my-5`}>
              <div className="row">
                <div className="col-6 col-sm-4 col-lg-2">
                  <div
                    className={`pro-w-100 ${Style.root}  ${
                      sideMenu === "expanded" ? Style.active : ""
                    }`}
                    onClick={() => {
                      handleDrawerThemeChange("expanded");
                    }}
                  >
                    <Image
                      src={Assets.THEME_EXPANDED}
                      width={180}
                      height={118}
                    />
                    <p className="pro-font-sm pro-fw-medium pro-mb-0 pro-mt-2">
                      Expanded
                    </p>
                  </div>
                </div>
                <div className="col-6 col-sm-4 col-lg-2">
                  <div
                    className={`pro-w-100 ${Style.root} ${
                      sideMenu === "closed" ? Style.active : ""
                    }`}
                    onClick={() => {
                      handleDrawerThemeChange("closed");
                    }}
                  >
                    <Image src={Assets.THEME_CLOSED} width={180} height={118} />
                    <p className="pro-font-sm pro-fw-medium pro-mb-0 pro-mt-2">
                      Closed
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetStudentDetailsDataQuery } from "../../../store/queries/students";
import Assets from "../../../assets/Assets";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateConfig } from "../../../store/slices/Students/studentsProfileSlice";
import {
  getStudentsDataByID,
  updateConfig as updateStudentsConfig,
} from "../../../store/slices/Students/studentsSlice";
import { useParams } from "react-router-dom/dist";

const useStudentsDetailLayout = () => {
  const dispatch = useDispatch();
  const printRef = useRef();
  const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);
  const [show, setShow] = useState(false);
  const ProfileState = useSelector((state) => state.studentProfile);
  const Studentstate = useSelector((state) => state.students);
  const { studentID } = useParams();
  const activeProfile = studentID ?? sessionStorage.getItem("active");

  const navigate = useNavigate();

  const {
    data: profileData,
    isFetching,
    isSuccess,
    isError,
    refetch,
  } = useGetStudentDetailsDataQuery({
    student_id: activeProfile,
  });

  const hasUpdatePermission = useMemo(() => {
    let permission = profileData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit ?? 0;
  }, [profileData]);

  useEffect(() => {
    if (profileData?.status_code === 400 || profileData === "") {
      toast.error(`Something went wrong!`);
      navigate("/");
    }
    // dispatch(
    //   updateConfig((state) => {
    //     state.profileOverviewData = profileData?.data?.rows;
    //   })
    // );
    // eslint-disable-next-line
  }, [profileData]);

  useEffect(() => {
    if (ProfileState.isEditProfile && isSuccess) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {
            ...profileData?.data?.data,
            user_id: ProfileState.selectedUser,
          };
        })
      );
    }
    // eslint-disable-next-line
  }, [ProfileState.isEditProfile, isSuccess]);

  const label = {
    gender: "Gender",
    country: "Country",
    state: "State",
    city: "City",
    phone: "Mobile",
    email: "Email",
    company_name: "Company Name",
    designation: "Designation",
    qualification: "Highest Qualification",
    dob: "Date Of Birth",
    pincode: "Zipcode",
    address_one: "Address 1",
    address_two: "Address 2",
    join_date: "Join Date",
  };

  const value = {
    gender: `${profileData?.data?.rows?.gender_name ?? "Not specified"}`,
    country: `${profileData?.data?.rows?.country_name ?? "Not specified"}`,
    state: `${profileData?.data?.rows?.state_name ?? "Not specified"}`,
    city: `${profileData?.data?.rows?.city ?? "Not specified"}`,
    phone: `${profileData?.data?.rows?.mobile_number ?? "Not specified"}`,
    email: `${profileData?.data?.rows?.email ?? "Not specified"}`,
    company_name: `${profileData?.data?.rows?.company_name ?? "Not specified"}`,
    designation: `${profileData?.data?.rows?.designation ?? "Not specified"}`,
    qualification: `${
      profileData?.data?.rows?.qualification_name ?? "Not specified"
    }`,
    dob: `${profileData?.data?.rows?.date_of_birth ?? "Not specified"}`,
    pincode: `${profileData?.data?.rows?.zipcode ?? "Not specified"}`,
    address_one: `${profileData?.data?.rows?.address_one ?? "Not specified"}`,
    address_two: `${profileData?.data?.rows?.address_two ?? "Not specified"}`,
    join_date: `${profileData?.data?.rows?.join_date ?? "Not specified"}`,
  };

  const basicDetails = Object.keys(value).map((key) => {
    return {
      label: label[key],
      value:
        typeof value[key] === "string"
          ? value?.[key]
          : typeof value[key] === "number"
          ? value?.[key]
          : value?.[key]?.name,
    };
  });

  const handleEditClick = () => {
    dispatch(getStudentsDataByID(profileData?.data?.rows?.id));
    dispatch(
      updateStudentsConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
      })
    );
  };

  const closeEditOffcanvas = () => {
    dispatch(
      updateStudentsConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCourseCreateModal = false;

        // state.selectedItemsDetails = ""
      })
    );
  };
  return {
    show,
    activeProfile,
    Studentstate,
    isFetching,
    printRef,
    closeModal,
    profileData,
    basicDetails,
    ProfileState,
    showDeleteImageModal,
    hasUpdatePermission,
    setShowDeleteImageModal,
    setShow,
    refetch,
    handleEditClick,
    closeEditOffcanvas,
  };
};

export default useStudentsDetailLayout;

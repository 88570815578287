import React from "react";
import Style from "./AddCourse.module.scss";
import { Input } from "@wac-ui-dashboard/wac_component_library";
import { capitalizeOnSpace } from "../../../../utils/functions/table";

const AddCourse = ({ formik, addState, handleRemoveField }) => {
  return (
    <>
      {[...Array(formik.values.course_modules?.length)]?.map((_, index) => {
        return (
          <div className={`${Style.wrapper}`}>
            {index !== 0 ? (
              <span
                className={`${Style.corner_button} btn-close`}
                onClick={() => handleRemoveField(index)}
              ></span>
            ) : null}
            <Input
              label={"Title*"}
              id={`course_modules[${index}].title`}
              name={`course_modules[${index}].title`}
              type="text"
              {...formik.getFieldProps(`course_modules[${index}].title`)}
              onChange={(event) =>
                capitalizeOnSpace(event, formik.setFieldValue)
              }
              className={`pro-input lg ${
                formik.touched.course_modules?.[index]?.title &&
                formik.errors.course_modules?.[index]?.title &&
                " error"
              }`}
              error={
                formik.touched.course_modules?.[index]?.title &&
                formik.errors.course_modules?.[index]?.title
              }
              errorMessage={formik.errors.course_modules?.[index]?.title}
            />

            <Input
              label={"Description"}
              id={`course_modules[${index}].description`}
              name={`course_modules[${index}].description`}
              type="text"
              {...formik.getFieldProps(`course_modules[${index}].description`)}
              className={`pro-input lg ${
                formik.touched.course_modules?.[index]?.description &&
                formik.errors.course_modules?.[index]?.description &&
                " error"
              }`}
              error={
                formik.touched.course_modules?.[index]?.description &&
                formik.errors.course_modules?.[index]?.description
              }
              errorMessage={formik.errors.course_modules?.[index]?.description}
            />

            <Input
              label={"Duration"}
              id={`course_modules[${index}].duration`}
              name={`course_modules[${index}].duration`}
              placeholder={`In Hours`}
              type="text"
              {...formik.getFieldProps(`course_modules[${index}].duration`)}
              className={`pro-input lg ${
                formik.touched.course_modules?.[index]?.duration &&
                formik.errors.course_modules?.[index]?.duration &&
                " error"
              }`}
              error={
                formik.touched.course_modules?.[index]?.duration &&
                formik.errors.course_modules?.[index]?.duration
              }
              errorMessage={formik.errors.course_modules?.[index]?.duration}
            />
          </div>
        );
      })}
    </>
  );
};

export default AddCourse;

import React from "react";
import Style from "./selectWithIcons.module.scss";
import Select, { components } from "react-select";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import Assets from "../../../../assets/Assets";

export const SelectWithIcons = ({
	options,
	onChange,
	multiSelect,
	isMultiSelected,
	noOptionsMessage,
	label,
	dashboard,
	handleInputChange,
	// handleViewTooltip,
	// handleHideTooltip,
	...rest
}) => {
	const customMultiValueLabel = (option) => {

		return (
			<components.MultiValueLabel {...option}>
				<div
					className={`pro-d-flex pro-items-center ${Style.avatar_container} avatar-container`}
				// onMouseLeave={!dashboard ? () => handleHideTooltip() : () => {}}
				>
					<div
						className={`pro-rounded-circle pro-overflow-hidden pro-align-self-center ${Style.img_wrap}`}
					// onMouseEnter={
					//   !dashboard ? (e) => handleViewTooltip(e, data) : () => {}
					// }
					>
						<Image
							src={option?.data?.icon?.props?.src ?? Assets.ACCOUNT_CIRCLE}
							width={16}
							height={16}
							alt={option?.data?.label}
						/>
					</div>
					<span className="pro-mx-2 pro-align-self-center name">
						{option?.data?.label}
					</span>
					<button
						className={`pro-multi-value-remove btn-close ${Style.btn_close}`}
						onClick={() => option.removeProps.onClick(option.data)}
					>
						{/* &times; */}
					</button>
				</div>
			</components.MultiValueLabel>
		);
	};

	const customOption = (option) => {

		return (
			<components.Option {...option}>
				<div className="pro-d-flex pro-items-center">
					<div className={`pro-avatar department-${option?.data?.value}`}>
						{/* {option?.data?.icon?.props?.src ?? Assets.ACCOUNT_CIRCLE} */}
					</div>
					<span className="pro-ps-1">{option?.data?.label}</span>
				</div>
			</components.Option>
		);
	};

	return (
		<div className={`pro-d-flex pro-gap-3 ${Style.select_input}`}>
			<span>To </span>
			<Select
				{...rest}
				onChange={onChange}
				//  onInputChange={(value) => handleInputChange(value)}
				isMulti={multiSelect}
				placeholder=" "
				// noOptionsMessage={noOptionsMessage}
				options={options}
				value={options?.filter((item) => isMultiSelected?.includes(item?.value))}
				classNamePrefix="pro-input"
				className={`pro-input multi-select lg   ${Style.pro_input}`}
				components={{
					Option: customOption,
					MultiValue: customMultiValueLabel,
				}}
				defaultValue={label}
			></Select>
		</div>
	);
};

import { useEffect, useState } from "react";
import { useGetDashboardDataQuery } from "../../../store/queries/Dashboard";

const useDashboardListing = () => {
  // const dispatch = useDispatch();
  const {
    data: dashboardData = {},
    isFetching,
    isSuccess,
  } = useGetDashboardDataQuery({});

  const [listingType, setListingType] = useState();

  useEffect(() => {
    if (isSuccess) {
      setListingType(Object.keys(dashboardData?.data || {})?.[0] ?? "");
    }

    //eslint-disable-next-line
  }, [isSuccess]);

  const handleEnquiryList = () => {
    setListingType("EnquiryList");
  };
  const handleRegisteredStudents = () => {
    setListingType("RegisteredStudents");
  };
  const handleRevenue = () => {
    setListingType("Revenue");
  };
  const handleActiveStudents = () => {
    setListingType("ActiveStudents");
  };
  const handlePassedOutStudents = () => {
    setListingType("PassedOutStudents");
  };
  const handleEndDateFilterCount = () => {
    setListingType("EndDateFilterCount");
  };

  let object = {
    EnquiryList: handleEnquiryList,
    RegisteredStudents: handleRegisteredStudents,
    Revenue: handleRevenue,
    ActiveStudents: handleActiveStudents,
    PassedOutStudents: handlePassedOutStudents,
    EndDateFilterCount: handleEndDateFilterCount,
  };

  const handleClick = (label) => {
    object[label]();
  };

  let dashboardItems = {
    EnquiryList: {
      label: "EnquiryList",
      title: "Enquiry List",
      icon: "person_raised_hand",
      handleClick,
      // component: <Messages dashboard={true} />,
    },
    RegisteredStudents: {
      label: "RegisteredStudents",
      title: "Registered Students",
      icon: "person",
      handleClick,
      // component: <Members dashboard={true} />,
    },
    Revenue: {
      label: "Revenue",
      title: "Revenue",
      icon: "savings",
      handleClick,
      // component: <Deleted dashboard={true} />,
    },
    ActiveStudents: {
      label: "ActiveStudents",
      title: "Active Students",
      icon: "groups",
      handleClick,
      // component: <Members dashboard={true} />,
    },
    PassedOutStudents: {
      label: "PassedOutStudents",
      title: "Passed Out Students",
      icon: "person_check",
      handleClick,
      // component: <DataUpdation dashboard={true} />,
    },
    EndDateFilterCount: {
      label: "EndDateFilterCount",
      title: "Course Lagging",
      icon: "timeline",
      handleClick,
    },
  };

  return {
    dashboardItems,
    dashboardData,
    isFetching,
    children: dashboardItems[listingType]?.component,
    listingType,
  };
};

export default useDashboardListing;

import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React, { useState } from "react";
import Select from "react-select";
import Style from "./subAdminForm.module.scss";
import useSubAdminForm from "./useSubAdminForm";
import {
  capitalizeInitial,
  capitalizeOnSpace,
  limitStrLength,
} from "../../../../utils/functions/table";
import { format, parse } from "date-fns";

const SubAdminForm = ({
  refetch,
  hasUpdatePermission,
  closeModal,
  userTypeData,
  isStickyFooter,
}) => {
  const {
    formik,
    imageCoverPreview,
    handleCoverImage,
    selectedItemsDetails,
    profilefileInputRef,
    cuntryCodeOptions,
    showPassword,
    showConfirmPassword,
    handleShowPassword,
    userTypeObj,
    handleShowConfirmPassword,
    handlePhoneNumberChange,
  } = useSubAdminForm({ refetch, closeModal, userTypeData });
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuIsOpen(() => true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(() => false);
  };

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"First name*"}
        type="text"
        id="first_name
          "
        name="first_name
          "
        className={`pro-input lg ${
          formik.errors.first_name && formik.touched.first_name && "error"
        }`}
        {...formik.getFieldProps("first_name")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.first_name && formik.touched.first_name}
        errorMessage={formik.errors.first_name}
      />

      <Input
        label={"Last name"}
        type="text"
        id="last_name
          "
        name="last_name
          "
        className={`pro-input lg ${
          formik.errors.last_name && formik.touched.last_name && "error"
        }`}
        {...formik.getFieldProps("last_name")}
        onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
        error={formik.errors.last_name && formik.touched.last_name}
        errorMessage={formik.errors.last_name}
      />

      <Input
        label={"Email address*"}
        type="text"
        id="email"
        name="email"
        placeholder="name@example.com"
        className={`pro-input lg ${
          formik.errors.email && formik.touched.email && " error"
        }`}
        {...formik.getFieldProps("email")}
        error={formik.errors.email && formik.touched.email}
        errorMessage={formik.errors.email}
      />
      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          User Type*
        </label>
        <Select
          id="user_type"
          name="user_type"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.user_type && formik.touched.user_type && " error"
          }`}
          classNamePrefix="pro-input"
          options={userTypeData?.data}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={userTypeObj}
          onChange={(value) => formik.setFieldValue("user_type", value.id)}
        />
        {formik.touched.user_type && formik.errors.user_type && (
          <span className="error-text">{formik.errors.user_type}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <div className="input-country-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Mobile number*
          </label>
          <div className="input-main-wrap ">
            <div className="code-wrap pro-mb-4 ">
              <Select
                id="dial_code"
                options={cuntryCodeOptions}
                // getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                onMenuOpen={handleMenuOpen}
                onMenuClose={handleMenuClose}
                menuIsOpen={menuIsOpen}
                formatOptionLabel={({ label, title }) =>
                  menuIsOpen ? title : label
                }
                className={` multi-select country-select ${
                  formik.touched.dial_code &&
                  formik.errors.dial_code &&
                  " error"
                }`}
                value={cuntryCodeOptions?.filter(
                  (p) =>
                    p.value?.toString() === formik.values.dial_code?.toString()
                )}
                classNamePrefix="pro-input"
                onBlur={() => formik.handleBlur("dial_code")}
                onChange={(value) =>
                  formik.setFieldValue("dial_code", value?.value)
                }
              ></Select>
            </div>
            <input
              type="text"
              id="mobile_number"
              name="mobile_number"
              className={`pro-input lg ${
                formik.errors.mobile_number &&
                formik.touched.mobile_number &&
                " error"
              }`}
              {...formik.getFieldProps("mobile_number")}
              error={
                formik.errors.mobile_number && formik.touched.mobile_number
              }
              errorMessage={formik.errors.mobile_number}
              onBlur={() => formik.handleBlur("mobile_number")}
              onChange={(e) => handlePhoneNumberChange(e.target.value)}
              // {...formik.getFieldProps("mobile_number")}
            />
          </div>
          {formik.touched.mobile_number && formik.errors.mobile_number && (
            <span className="error-text">{formik.errors.mobile_number}</span>
          )}
        </div>
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Photo
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched.image && formik.errors.image && " error"
            }`}
            id="image"
            name="image"
            onBlur={formik.handleBlur("image")}
            onChange={(e) => handleCoverImage(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.image?.name !== undefined
                  ? limitStrLength(formik?.values?.image?.name, 30)
                  : selectedItemsDetails !== "" &&
                    selectedItemsDetails?.original_image_name
                  ? selectedItemsDetails?.original_image_name
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.image && formik.errors.image && (
            <span className="error-text">{formik.errors.image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageCoverPreview}
            alt={`magazine - 01`}
          />
        </div>
      </div>

      {selectedItemsDetails === "" && (
        <div className="p-re">
          <Input
            label={"Password*"}
            type="password"
            id="password"
            autoComplete="new-password"
            name="password"
            className={`pro-input lg ${
              formik.errors.password && formik.touched.password && " error"
            }`}
            {...formik.getFieldProps("password")}
            error={formik.errors.password && formik.touched.password}
            errorMessage={formik.errors.password}
          />
          {showPassword ? (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowPassword(e)}
            >
              <span className="material-symbols-outlined">
                {" "}
                visibility_off{" "}
              </span>
            </button>
          ) : (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowPassword(e)}
            >
              <span className="material-symbols-outlined"> visibility </span>
            </button>
          )}
        </div>
      )}

      {selectedItemsDetails === "" && (
        <div className="p-re">
          <Input
            label={"Re-enter password*"}
            type="password"
            id="confirm_password"
            autoComplete="new-password"
            name="confirm_password"
            className={`pro-input lg ${
              formik.errors.confirm_password &&
              formik.touched.confirm_password &&
              " error"
            }`}
            {...formik.getFieldProps("confirm_password")}
            error={
              formik.errors.confirm_password && formik.touched.confirm_password
            }
            errorMessage={formik.errors.confirm_password}
          />
          {showConfirmPassword ? (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowConfirmPassword(e)}
            >
              <span className="material-symbols-outlined">
                {" "}
                visibility_off{" "}
              </span>
            </button>
          ) : (
            <button
              className={`hide-icon`}
              onClick={(e) => handleShowConfirmPassword(e)}
            >
              <span className="material-symbols-outlined"> visibility </span>
            </button>
          )}
        </div>
      )}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default SubAdminForm;

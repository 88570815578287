import { useMemo, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { getFormatedDate } from "../../../utils/functions/table";
import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";

import {
  useGetInvoicesDataQuery,
  useUpdateInvoiceListFieldsDataMutation,
} from "../../../store/queries/invoices";
import useObserver from "../../../utils/hooks/useObserver";
import { updateConfig } from "../../../store/slices/Invoices/invoicesSlice";
import { useDebounce } from "../../../utils/hooks/useDebounce";
import { getInvoiceEditData } from "../InvoiceTable/api";
import { toast } from "react-toastify";
import { EnquirySelectWithIcons } from "../../Enquires/EnquirySelectWithIcons";
import Style from "./invoice.module.scss";

const useInvoice = (dashboard) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuState = useSelector((state) => state.invoices);
  const [selectedId, setSelectedId] = useState([]);
  const [skip, setSkip] = useState(true);
  const [updateInvoiceFields] = useUpdateInvoiceListFieldsDataMutation();
  const { showCreateModal, selctedId } = useSelector((state) => state.courses);
  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [showImageModal, setShowImageModal] = useState(false);

  const [imageData, setImageData] = useState("");
  const debouncedSearchTerm = useDebounce(menuState.search, 1000);

  const {
    data: mainData = {},
    isFetching,
    isSuccess,
    isLoading,
    refetch,
  } = useGetInvoicesDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: debouncedSearchTerm,
    start: menuState.startDate,
    end: menuState.endDate,
    length: menuState.currentPageSize,
    page: menuState.currentPage,
  });

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);

  // const { data: enquiryViewData = {} } = useGetEnquiryViewDataQuery(
  //   {
  //     digital_enquiry_id: selectedId,
  //     page: 1,
  //   },
  //   { skip }
  // );

  // const { data: optionsData = {} } = useGetMemberCreationFormDataQuery({});

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const hasCreatePermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [mainData]);

  const hasEditPermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit;
    // eslint-disable-next-line
  }, [mainData]);

  const hasUpdatePermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update;
    // eslint-disable-next-line
  }, [mainData]);

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handleEditAction = (data) => {
    setSkip(() => false);
    setSelectedId(data?.[0]);
    getInvoiceEditData(data?.[0]).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => {
            state.showCreateModal = true;
            state.editData = response?.data;
          })
        );
      } else {
        toast.error(`Failed to edit payment`);
      }
    });
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      PaymentStatus: (feild, data) => {
        return (
          <>
            {data[feild] === "Paid" ? (
              <p className="pro-badge badge-success-outline pro-mb-0">
                {data[feild]}
              </p>
            ) : (
              <button
                onClick={() => handleAddPayment(data?.id, data?.pending_amount)}
                className="pro-btn pro-btn-primary"
              >
                Add Payment
              </button>
            )}
          </>
        );
      },
      transactions: (feild, data) => {
        let transactionList = data?.transactions?.map((item, i) => {
          return {
            value: item?.id,
            label: `Print Transaction ${i + 1}`,
          };
        });
        return (
          <div className={`${Style.color_dropdown}`}>
            <EnquirySelectWithIcons
              // menuPlacement={checkTop(data?.id) ? "top" : "bottom"}
              menuPlacement={"bottom"}
              menuPosition="fixed"
              options={transactionList}
              multiSelect={false}
              onChange={(e) => handleTransactionPrint(e, data)}
              name="select_staff"
              noteIcon={false}
              // notes={data?.notes?.length}
              placeholder="Print Payment Transaction"
              // handleViewTooltip={handleViewTooltip}
              // handleHideTooltip={handleHideTooltip}
              data={data}
            />
          </div>
        );
      },
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };
  const handleTransactionPrint = (item, data) => {
    dispatch(
      updateConfig((state) => {
        state.showInvoiceModal = true;
        state.invoiceLoading = true;
        state.transactionId = item?.value;
      })
    );
  };

  const handleEditClick = () => {
    // dispatch(
    //   updateConfig((state) => {
    //     state.selectedItemsDetails = enquiryViewData?.data ?? "";
    //   })
    // );
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };

  // const handleViewImage = (data) => {
  //   setShowImageModal(() => true);
  //   setImageData(data?.cover_photo?.url);
  // };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = null;
        state.endDate = null;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.invoiceLoading = false;
      })
    );
  };

  const handleDashboardRedirect = (filter) => {
    navigate("/payment/mainData");
  };

  const handleLoadMore = useCallback(() => {
    if (isSuccess) {
      dispatch(
        updateConfig((state) => {
          state.scrollPage = mainData?.data?.rows?.enquiries?.current_page + 1;
        })
      );
    }
    //eslint-disable-next-line
  }, [dispatch, isSuccess, mainData]);

  const { lastElement } = useObserver({
    loading: isLoading,
    hasMore:
      mainData?.data?.rows?.enquiries?.per_page *
        mainData?.data?.rows?.enquiries?.current_page <
      mainData?.data?.rows?.enquiries?.total,
    callback: handleLoadMore,
  });
  const closeInvoiceModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showInvoiceModal = false;
      })
    );
  };

  const handlePaymentClose = () => {
    dispatch(
      updateConfig((state) => {
        state.showPaymentModal = false;
      })
    );
  };

  const handleAddPayment = (id, netPayable) => {
    dispatch(
      updateConfig((state) => {
        state.IDs = { invoiceID: id };
        state.netPayableAmount = netPayable;
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showPaymentModal = true;
        state.receivedAmount = "";
      })
    );
  };

  return {
    mainData,
    menuState,
    currentPage: menuState.currentPage,
    showCreateModal,
    hasCreatePermission,
    isFetching,
    isLoading,

    date,
    // optionsData,
    showEditModal,
    tableFields,
    paginationOptions,
    selctedId,
    actionOptions,
    updateInvoiceFields,
    // enquiryViewData,
    // hasExportPermission,
    showImageModal,
    imageData,
    hasEditPermission,
    hasUpdatePermission,
    selectedId,
    handlePaymentClose,
    lastElement,
    closeInvoiceModal,
    // updateEnquiryStatus,
    handleEditAction,
    closeImageModal,
    handleDashboardRedirect,
    handleActionChange,
    refetch,
    closeModal,
    // updateEnquiryFields,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleClearClick,
  };
};

export default useInvoice;

import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("start_date")}${getParams(
          "end_date"
        )}${getParams("filter")}${getParams("sort_by")}${getParams(
          "end_date_filter"
        )}${getParams("course_status")}${getParams("stream_id")}${getParams(
          "course_status"
        )}${getParams("course_id")}${getParams("branch_id")}${getParams(
          "join_start_date"
        )}sort_order=${params?.sort_order || "desc"}&length=${
          params?.length || "10"
        }&${getParams("search")}page=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const students = createApi({
  reducerPath: "studentsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "students",
    "studentDetails",
    "profileCourse",
    "profilePayments",
    "basicData",
    "courseLists",
    "salesList",
    "facultyLists",
    "countryLists",
    "studentDetailsReport",
  ],
  endpoints: (builder) => ({
    // Deleted Accounts

    getStudentsListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/student`,
      }),
      providesTags: ["students"],
    }),

    getStudentDetailsData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/student/show/${values.student_id}`,
      }),
      providesTags: ["studentDetails"],
    }),

    getStudentDetailsReportData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/student/calculate-percentage/${values.student_id}`,
      }),
      providesTags: ["studentDetailsReport"],
    }),

    getProfilePaymentListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/invoice/get-student-invoice/${values.student_id}`,
      }),
      providesTags: ["profilePayments"],
    }),

    getProfileCourseListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/student/student-course/${values?.student_id}`,
      }),
      providesTags: ["profileCourse"],
    }),

    updateStudentsListFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/table-permission-create`,
      }),

      invalidatesTags: ["students"],
    }),

    createStudent: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/student/store`,
      }),
      invalidatesTags: ["students"],
    }),

    updateStudent: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/student/update/${body.id}`,
      }),
      invalidatesTags: ["students"],
    }),
    updateCourseStatus: builder.mutation({
      query: (body, id) => ({
        method: "post",
        body,
        endpoint: `/v1/student/update-student-course-module-status/${body?.id}`,
      }),
      invalidatesTags: ["profileCourse"],
    }),
    createNote: builder.mutation({
      query: (body, id) => ({
        method: "post",
        body,
        endpoint: `/v1/note/store`,
      }),
    }),

    sendMail: builder.mutation({
      query: (body, id) => ({
        method: "post",
        body,
        endpoint: `/v1/email/send-mail`,
      }),
    }),
    getBasicData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/country`,
      }),
      providesTags: ["countryLists"],
    }),
    getCourseData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/course/list-all`,
      }),
      providesTags: ["courseLists"],
    }),
    getSalesTeamData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/enquiry/list-user-sales`,
      }),
      providesTags: ["salesList"],
    }),
    getFacultyData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/faculty/fetch-all-faculty`,
      }),
      providesTags: ["facultyLists"],
    }),

    getBasicOptionData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/settings/fetch-basic-apis`,
      }),
      providesTags: ["basicData"],
    }),
  }),
});

export const {
  useGetStudentsListDataQuery,
  useGetStudentDetailsReportDataQuery,
  useGetStudentDetailsDataQuery,
  useGetFacultyDataQuery,
  useUpdateStudentsListFieldsDataMutation,
  useCreateStudentMutation,
  useGetCourseDataQuery,
  useGetSalesTeamDataQuery,
  useGetBasicDataQuery,
  useGetBasicOptionDataQuery,
  useUpdateStudentMutation,
  useUpdateCourseStatusMutation,
  useCreateNoteMutation,
  useSendMailMutation,
  useGetProfileDataQuery,
  useGetProfilePaymentListDataQuery,
  useGetProfileCourseListDataQuery,
} = students;

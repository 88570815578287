import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useRouteUtils from "../../../utils/hooks/useRouteUtils";
import { useEffect, useMemo } from "react";
import useRoutes from "../../../routes/useRoutes";
import { useDispatch } from "react-redux";
import { getMenuPermissions, updateConfig } from "../../../store/slices/Global";
const useCommonLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { menuDrawer } = useSelector((state) => state.global);

  const globalState = useSelector((state) => state.global);

  const {
    allowedDashboardComponent,
    allowedEnquiresComponent,
    allowedStudenstComponent,
    allowedSettingsComponent,
    allowedEnrollComponent,
    // allowedBranchesComponent,
    allowedCoursesComponent,
    // allowedActionLogsComponent,
    allowedFaculitiesComponent,
    allowedSalesComponent,
    allowedInvoicesComponent,
    allowedTimeTableComponent,
    // allowedSubAdminsComponent,
  } = useRoutes();
  // useEffect(() => {
  //   console.log("first nav");
  //   if (!globalState.isLogged && location.pathname === "/settings/appearance") {
  //     console.log("hereee");
  //     dispatch(
  //       updateConfig((state) => {
  //         state.isLogged = true;
  //       })
  //     );
  //   } else {
  //     console.log("here2");
  //   }

  //   //eslint-disable-next-line
  // }, [dispatch, globalState.isLogged]);

  useEffect(() => {
    let sessionLogged = sessionStorage.getItem("USER_ACCESS_TOKEN");

    if (!sessionLogged) {
      sessionStorage.setItem(
        "USER_ACCESS_TOKEN",
        localStorage.getItem("USER_ACCESS_TOKEN")
      );

      dispatch(
        getMenuPermissions({ token: localStorage.getItem("USER_ACCESS_TOKEN") })
      );
    }

    //eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (
      globalState.isLogged === true &&
      (allowedDashboardComponent?.length > 0 ||
        allowedEnquiresComponent?.length > 0 ||
        allowedEnrollComponent?.length > 0 ||
        allowedStudenstComponent?.length > 0 ||
        allowedCoursesComponent?.length > 0 ||
        allowedFaculitiesComponent?.length > 0 ||
        allowedSalesComponent?.length > 0 ||
        allowedInvoicesComponent?.length > 0 ||
        allowedTimeTableComponent?.length > 0 ||
        // allowedSubAdminsComponent?.length > 0 ||
        allowedSettingsComponent?.length > 0)
    ) {
      // setTimeout(() => {
      dispatch(
        updateConfig((state) => {
          state.isLogged = "idle";
        })
      );
      // }, 1800);

      if (allowedDashboardComponent?.length > 0) {
        navigate(
          `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedEnquiresComponent?.length > 0) {
        navigate(
          `/${allowedEnquiresComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedEnrollComponent?.length > 0) {
        navigate(`/${allowedEnrollComponent?.map((item) => item?.path)?.[0]}`);
      } else if (allowedStudenstComponent?.length > 0) {
        navigate(
          `/${allowedStudenstComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedTimeTableComponent?.length > 0) {
        navigate(
          `/${allowedTimeTableComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      // else if (allowedBranchesComponent?.length > 0) {
      //   navigate(
      //     `/${allowedBranchesComponent?.map((item) => item?.path)?.[0]}`
      //   );
      // }
      else if (allowedCoursesComponent?.length > 0) {
        navigate(`/${allowedCoursesComponent?.map((item) => item?.path)?.[0]}`);
      } else if (allowedFaculitiesComponent?.length > 0) {
        navigate(
          `/${allowedFaculitiesComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedSalesComponent?.length > 0) {
        navigate(`/${allowedSalesComponent?.map((item) => item?.path)?.[0]}`);
      } else if (allowedInvoicesComponent?.length > 0) {
        navigate(
          `/${allowedInvoicesComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      // else if (allowedSubAdminsComponent?.length > 0) {
      //   navigate(
      //     `/${allowedSubAdminsComponent?.map((item) => item?.path)?.[0]}`
      //   );
      // } else if (allowedActionLogsComponent?.length > 0) {
      //   navigate(
      //     `/${allowedActionLogsComponent?.map((item) => item?.path)?.[0]}`
      //   );
      // }
      else {
        navigate(
          `/settings/${
            allowedSettingsComponent?.map((item) => item?.path)?.[0]
          }`
        );
      }
    }

    //eslint-disable-next-line
  }, [
    allowedEnquiresComponent,
    allowedStudenstComponent,
    allowedSettingsComponent,
    // allowedBranchesComponent,
    allowedFaculitiesComponent,
    // allowedActionLogsComponent,
    allowedCoursesComponent,
    allowedEnrollComponent,
    allowedSalesComponent,
    allowedCoursesComponent,
    allowedInvoicesComponent,
    allowedTimeTableComponent,
    // allowedSubAdminsComponent,
    allowedDashboardComponent,
  ]);

  const { checkIfActiveRoute } = useRouteUtils();
  const navigations = [
    {
      label: "Dashboard",
      link: `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/dashboard"),
    },
    {
      label: "Enquiry",
      link: `/${allowedEnquiresComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/enquires`),
    },
    {
      label: "Enrolled",
      link: `/${allowedEnrollComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/enroll`),
    },
    {
      label: "Students",
      link: `/${allowedStudenstComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/students`),
    },
    // {
    //   label: "Branches",
    //   link: `/${allowedBranchesComponent?.map((item) => item?.path)?.[0]}`,
    //   isButton: false,
    //   active: checkIfActiveRoute(`/branches`),
    // },
    {
      label: "Courses",
      link: `/${allowedCoursesComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/courses`),
    },
    {
      label: "Faculties",
      link: `/${allowedFaculitiesComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/faculties"),
    },
    {
      label: "Time Table",
      link: `/${allowedTimeTableComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/time-table"),
    },
    {
      label: "Sales",
      link: `/${allowedSalesComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute("/sales"),
    },
    {
      label: "Payments",
      link: `/${allowedInvoicesComponent?.map((item) => item?.path)?.[0]}`,
      isButton: false,
      active: checkIfActiveRoute(`/payments`),
    },

    // {
    //   label: "Sub-admin",
    //   link: `/${allowedSubAdminsComponent?.map((item) => item?.path)?.[0]}`,
    //   isButton: false,
    //   active: checkIfActiveRoute(`/sub-admins`),
    // },
    // {
    //   label: "Action Logs",
    //   link: `/${allowedActionLogsComponent?.map((item) => item?.path)?.[0]}`,
    //   isButton: false,
    //   active: checkIfActiveRoute("/action-logs"),
    // },
    {
      label: "Settings",
      link: `/settings/${
        allowedSettingsComponent?.map((item) => item?.path)?.[0]
      }`,
      isButton: false,
      active: checkIfActiveRoute("/settings"),
    },
  ];

  const handleGoBack = () => {
    navigate(menuDrawer?.backTo);
  };

  const topMenuPermission = useMemo(() => {
    let menus = globalState.dashboard_permissions?.modules?.flatMap?.(
      (menu) => [
        menu.menu_name,
        // "Dashboard",
      ]
    );

    return navigations
      .filter((menu) => menus?.includes?.(menu.label))
      .map((menu) => {
        return {
          ...menu,
        };
      });
    //eslint-disable-next-line
  }, [globalState.dashboard_permissions, location.pathname]);

  return {
    navigations: topMenuPermission,
    menuDrawer,
    globalState,
    handleGoBack,
  };
};

export default useCommonLayout;

import { Button } from "@wac-ui-dashboard/wac_component_library";
import React, { useState } from "react";
import Select from "react-select";
import Style from "./enquiryForm.module.scss";
import useEnquiryForm from "./useEnquiryForm";
import { capitalizeOnSpace } from "../../../../utils/functions/table";

const EnquiryForm = ({
  refetch,
  refetchStatus,
  optionsData,
  showCreateModal,
  hasEditPermission,
  closeModal,
  isStickyFooter,
}) => {
  const {
    formik,
    getFieldError,
    cuntryCodeOptions,
    userData,
    streamData,
    sourceData,
    selectedItemsDetails,

    isLoading,
  } = useEnquiryForm({ refetch, refetchStatus, closeModal, showCreateModal });

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuIsOpen(() => true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(() => false);
  };

  return (
    <div className={`pro-w-100`}>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Name*
        </label>
        <input
          type="text"
          name="name"
          placeholder="Name"
          onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
          className={`pro-input lg ${
            formik.errors.name && formik.touched.name && "error"
          }`}
          onBlur={formik.handleBlur}
          value={formik.values.name}
        />
        {formik.touched.name && formik.errors.name && (
          <span className="error-text">{formik.errors.name}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Assigned to*
        </label>
        <Select
          id="assigned_to"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.assigned_to && formik.errors.assigned_to && " error"
          }`}
          classNamePrefix="pro-input"
          options={userData?.rows}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.full_name}
          value={userData?.rows?.filter(
            (m) => formik.values.assigned_to === m?.id
          )}
          onBlur={formik.handleBlur("assigned_to")}
          onChange={(value) => {
            formik.setFieldValue("assigned_to", value?.id || null);
          }}
        />
        {formik.touched.assigned_to && formik.errors.assigned_to && (
          <span className="error-text">{formik.errors.assigned_to}</span>
        )}{" "}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Stream
        </label>
        <Select
          id="stream_id"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.stream_id && formik.errors.stream_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={streamData?.rows}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={streamData?.rows?.filter(
            (m) => formik.values.stream_id === m?.id
          )}
          onBlur={formik.handleBlur("stream_id")}
          onChange={(value) => {
            formik.setFieldValue("stream_id", value?.id || null);
          }}
        />
        {formik.touched.stream_id && formik.errors.stream_id && (
          <span className="error-text">{formik.errors.stream_id}</span>
        )}{" "}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Source*
        </label>
        <Select
          id="source_id"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.source_id && formik.errors.source_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={sourceData}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.source}
          value={
            Array.isArray(sourceData)
              ? sourceData?.filter((m) => formik.values.source_id === m?.id)
              : []
          }
          onBlur={formik.handleBlur("source_id")}
          onChange={(value) => {
            formik.setFieldValue("source_id", value?.id || null);
          }}
        />
        {formik.touched.source_id && formik.errors.source_id && (
          <span className="error-text">{formik.errors.source_id}</span>
        )}{" "}
      </div>

      <div className="input-wrap pro-mb-4">
        <div className="input-country-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Mobile number*
          </label>
          <div className="input-main-wrap verify-btn-wrap">
            <div className="code-wrap pro-mb-4 ">
              <Select
                id="dial_code"
                options={cuntryCodeOptions}
                placeholder="Select"
                // getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                onMenuOpen={handleMenuOpen}
                onMenuClose={handleMenuClose}
                menuIsOpen={menuIsOpen}
                formatOptionLabel={({ label, title }) =>
                  menuIsOpen ? title : label
                }
                className={` multi-select country-select ${
                  formik.touched.dial_code &&
                  formik.errors.dial_code &&
                  " error"
                }`}
                value={cuntryCodeOptions?.filter(
                  (p) =>
                    p.value?.toString() === formik.values.dial_code?.toString()
                )}
                classNamePrefix="pro-input"
                onBlur={() => formik.handleBlur("dial_code")}
                onChange={(value) =>
                  formik.setFieldValue("dial_code", value?.value)
                }
              ></Select>
            </div>
            <input
              type="text"
              id="phone_number"
              name="phone_number"
              className={`pro-input lg ${
                formik.errors.phone_number &&
                formik.touched.phone_number &&
                " error"
              }`}
              {...formik.getFieldProps("phone_number")}
              error={formik.errors.phone_number && formik.touched.phone_number}
              errorMessage={formik.errors.phone_number}
              onBlur={() => formik.handleBlur("phone_number")}
              onChange={(event) => {
                // Remove any non-digit characters from the input
                const numericValue = event.target.value.replace(/\D/g, "");
                // Update the formik value with the numeric value
                formik.setFieldValue("phone_number", numericValue);
              }}
              // {...formik.getFieldProps("phone_number")}
            />
          </div>
          {formik.touched.phone_number && formik.errors.phone_number && (
            <span className="error-text">{formik.errors.phone_number}</span>
          )}
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Email
        </label>
        <input
          type="email"
          name="email"
          placeholder="Email"
          onChange={formik.handleChange}
          className={`pro-input lg ${
            formik.errors.email && formik.touched.email && "error"
          }`}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email && (
          <span className="error-text">{formik.errors.email}</span>
        )}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Message
        </label>
        <textarea
          id="message"
          rows={4}
          name="message"
          className={`pro-input lg ${
            formik.touched.message && formik.errors.message && " error"
          }`}
          {...formik.getFieldProps("message")}
        ></textarea>
        {formik.touched.message && formik.errors.message && (
          <span className="error-text">{formik.errors.message}</span>
        )}
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${
            isLoading ? "loading" : ""
          }`}
          type="submit"
          disabled={hasEditPermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default EnquiryForm;

import React from "react";
import Select from "react-select";
import Style from "./timeSlotEntries.module.scss";
import useTimeSlotEntries from "./useTimeSlotEntries";

const TimeSlotEntries = ({
  formik,
  fields,
  fieldIndex,
  daysList,
  timeSlotList,
  handleAddField,
}) => {
  const { daysObj, timeSlotObj, handleRemoveField } = useTimeSlotEntries({
    formik,
    fields,
    daysList,
    timeSlotList,
  });
  return (
    <div className={Style.formFields}>
      {formik?.values?.timetable_entries?.length !== 1 && (
        <span
          className={`${Style.corner_button} btn-close`}
          onClick={() => handleRemoveField(fieldIndex)}
        ></span>
      )}
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          day
        </label>
        <Select
          id="day_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik?.touched?.timetable_entries?.[fieldIndex]?.day_id &&
            formik?.errors?.timetable_entries?.[fieldIndex]?.day_id &&
            " error"
          }`}
          classNamePrefix="pro-input"
          options={daysList}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.label}
          value={daysObj}
          onBlur={formik.handleBlur(`timetable_entries.${fieldIndex}.day_id`)}
          onChange={(value) => {
            formik.setFieldValue(
              `timetable_entries.${fieldIndex}.day_id`,
              value?.value ?? ""
            );
          }}
        />
        {formik?.touched?.timetable_entries?.[fieldIndex]?.day_id &&
          formik?.errors?.timetable_entries?.[fieldIndex]?.day_id && (
            <span className="error-text">
              {formik?.errors?.timetable_entries?.[fieldIndex]?.day_id}
            </span>
          )}{" "}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Time Slot
        </label>
        <Select
          id="time_slot_ids"
          name="time_slot_ids"
          isClearable={false}
          isMulti
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik?.touched?.timetable_entries?.[fieldIndex]?.time_slot_ids &&
            formik?.errors?.timetable_entries?.[fieldIndex]?.time_slot_ids &&
            " error"
          }`}
          classNamePrefix="pro-input"
          options={timeSlotList}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.slot}
          value={timeSlotList?.filter((m) =>
            formik?.values?.timetable_entries?.[
              fieldIndex
            ]?.time_slot_ids?.includes?.(m?.id)
          )}
          onBlur={formik.handleBlur(
            `timetable_entries.${fieldIndex}.time_slot_ids`
          )}
          onChange={(value) =>
            formik.setFieldValue(
              `timetable_entries.${fieldIndex}.time_slot_ids`,
              value.map((v) => v?.id)
            )
          }
          menuPlacement="auto"
        />
        {formik?.touched?.timetable_entries?.[fieldIndex]?.time_slot_ids &&
          formik?.errors?.timetable_entries?.[fieldIndex]?.time_slot_ids && (
            <span className="error-text">
              {formik?.errors?.timetable_entries?.[fieldIndex]?.time_slot_ids}
            </span>
          )}{" "}
      </div>
    </div>
  );
};

export default TimeSlotEntries;

import { useMemo, useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { getFormatedDate } from "../../../utils/functions/table";
import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  updateConfig,
  getBranchDataByID,
  deleteBranch,
} from "../../../store/slices/Branches/branchesSlice.js";
import {
  useGetBranchDataQuery,
  useUpdateBranchesListFieldsDataMutation,
  useGetBasicDataQuery,
} from "../../../store/queries/branches";
import useObserver from "../../../utils/hooks/useObserver";
import { toast } from "react-toastify";
import { useDebounce } from "../../../utils/hooks/useDebounce";

const useBranch = (dashboard) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuState = useSelector((state) => state.branches);
  const [activeFilter, setActiveFilter] = useState(2);
  const [selectedId, setSelectedId] = useState([]);
  const [skip, setSkip] = useState(true);

  const { showCreateModal, selctedId } = useSelector((state) => state.branches);
  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [showImageModal, setShowImageModal] = useState(false);

  const [imageData, setImageData] = useState("");

  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetBasicDataQuery({});

  const options = [
    { value: 1, label: "Active" },
    { value: 0, label: "Inactive" },
  ];

  const debouncedSearchTerm = useDebounce(menuState.search, 1000);

  const [selectedOption, setSelectedOption] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");

  const tableRef = useRef(null);

  const {
    data: mainData = {},
    isFetching,
    isSuccess,
    isLoading,
    refetch,
  } = useGetBranchDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: debouncedSearchTerm,
    length: menuState.currentPageSize,
    page: menuState.currentPage,
    is_active: menuState.is_active,
  });

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const [updateBrancheFields] = useUpdateBranchesListFieldsDataMutation();
  // const [updateEnquiryStatus] = useUpdateEnquiryListStatusMutation();

  // useEffect(() => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.selectedItemsDetails = enquiryViewData?.data ?? "";
  //     })
  //   );

  //   // eslint-disable-next-line
  // }, [enquiryViewData]);

  // const hasExportPermission = useMemo(() => {
  //   let permission = mainData?.data?.permissions?.filter((p) =>
  //     Object.keys(p).includes("can_export")
  //   );

  //   return permission?.[0]?.can_export ?? 0;
  // }, [mainData]);

  const hasCreatePermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );

    return permission?.[0]?.can_create ?? 0;
  }, [mainData]);

  const hasEditPermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasUpdatePermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_update")
    );
    return permission?.[0]?.can_update ?? 0;
    // eslint-disable-next-line
  }, [mainData]);

  const hasDeletePermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );
    return permission?.[0]?.can_delete ?? 0;
  }, [mainData]);

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );

    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handleEditAction = (data) => {
    setSkip(() => false);
    setSelectedId(data?.[0]);
    dispatch(getBranchDataByID(data?.[0]));
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
      state_id: (field, data) => (
        <p className="pro-mb-0">{data["state"]?.state_name ?? ""}</p>
      ),
      country_id: (field, data) => (
        <p className="pro-mb-0">{data["country"]?.country_name ?? ""}</p>
      ),
      user_id: (field, data) => (
        <p className="pro-mb-0">{data["user"]?.full_name ?? ""}</p>
      ),
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data?.cover_photo?.url);
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
        state.clearSelection = true;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.is_active = null;
      })
    );
  };

  const handleDashboardRedirect = (filter) => {
    navigate("/branches");
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    dispatch(
      updateConfig((state) => {
        state.is_active = selectedOption.value;
      })
    );
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteBranch(selectedItemID[0]))
        .unwrap()
        .then((result) => {
          if (result?.message === "Success") {
            toast.success(result?.message);
          } else {
            toast.error(result?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  return {
    mainData,
    menuState,
    currentPage: menuState.currentPage,
    showCreateModal,
    hasCreatePermission,
    isFetching,
    isLoading,
    date,
    optionsData,
    showEditModal,
    tableFields,
    paginationOptions,
    selctedId,
    actionOptions,
    // enquiryViewData,
    // hasExportPermission,
    showImageModal,
    imageData,
    hasEditPermission,
    hasUpdatePermission,
    hasDeletePermission,
    handleEditAction,
    closeImageModal,
    handleDashboardRedirect,
    handleActionChange,
    refetch,
    updateBrancheFields,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleClearClick,
    handleChange,
    handleDelete,
    options,
    selectedOption,
    tableRef,
    setShowDeleteConfirm,
    showDeleteConfirm,
    handleDeleteAction,
  };
};

export default useBranch;

import React from "react";
import Style from "./invoiceTable.module.scss"; // Make sure to import your CSS file
import useInvoice from "./useInvoice";
import { useSelector } from "react-redux";
import InvoiceShimmer from "../../Global/Shimmers/InvoiceShimmer";
import moment from "moment";

const InvoiceTable = () => {
  const {
    handleDownloadClick,
    handlePrintClick,
    globalState,
    invoice,
    invoiceRef,
  } = useInvoice();
  const { invoiceLoading } = useSelector((state) => state.invoices);
  return (
    <>
      {invoiceLoading ? (
        <InvoiceShimmer />
      ) : (
        <>
          <div className="pro-p-5" ref={invoiceRef}>
            <div className={`${Style.header}`}>
              <div className={`${Style.left}`}>
                <p className={`${Style.content}`}>
                  <b> {invoice?.invoice?.student?.branch?.branch_name}</b>
                  <br />
                  {`${invoice?.invoice?.student?.branch?.address_one},${invoice?.invoice?.student?.branch?.address_two}`}
                  <br />
                  {`${invoice?.invoice?.student?.branch?.state?.state_name}, ${invoice?.invoice?.student?.branch?.state?.country?.country_name}`}
                  <br />
                  <a href={`${invoice?.invoice?.student?.branch?.user?.email}`}>
                    {invoice?.invoice?.student?.branch?.user?.email}
                  </a>
                  <br />
                  <a href={"https://caddintl.com"} target="_blank">
                    https://caddintl.com
                  </a>
                </p>
              </div>
              <div
                className={`${Style.right} pro-d-flex pro-flex-column justify-content-center pro-p-1`}
              >
                <div>
                  <img
                    className={`${Style.image}`}
                    src="https://ci3.googleusercontent.com/mail-sig/AIorK4xc3_6fTJro7gbxZa5aR8f5vvJWNuR50ZXBbLDUJpr4W2H9MEJ_imYa2LnabEKbiOxT_ufrlEE"
                    alt="Logo"
                    width="100"
                  />
                </div>
                <div style={{ marginTop: "15px" }}>
                  <img
                    className={`${Style.image}`}
                    src="https://ci3.googleusercontent.com/mail-sig/AIorK4wmSDKLDd_ZpBbkkb8zt9Em11RrauwSVxC0UsR9Yct6I-WxICXFqynMncKJBeADe2Ppa_x0_m4"
                    alt="Logo"
                    width="100"
                  />
                </div>
              </div>
            </div>

            <h5 className="pro-mb-3">Receipt</h5>
            <div className={`${Style.middle}`}>
              <div className={`${Style.left}`}>
                <p>
                  BILL TO
                  <br />
                  <span>{invoice?.invoice?.student?.full_name}</span>
                </p>
              </div>
              <div className={`${Style.middle_right}`}>
                <p className={`${Style.content} pro-d-flex pro-items-center`}>
                  Date:{" "}
                  <span className="pro-ms-auto">
                    {moment(invoice?.transaction_date)
                      .startOf("day")
                      .format("DD-MM-YYYY")}
                  </span>
                </p>
                {/* <p>Due Date</p> */}
                <p>
                  PAYMENT METHOD
                  <br />
                  <span>{invoice?.transaction_type}</span>
                </p>
              </div>
            </div>

            <table className={`${Style.invoiceTable}`}>
              <thead>
                <tr>
                  <th></th>
                  <th>Payment Date</th>
                  <th>Payment</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>
                    {moment(invoice?.transaction_date)
                      .startOf("day")
                      .format("DD-MM-YYYY")}
                  </td>
                  <td>{invoice?.amount}</td>
                </tr>
              </tbody>
            </table>
            <div
              className={`pro-d-flex pro-justify-between pro-items-center ${Style.footer}`}
            >
              {/* <p className={`${Style.content} pro-font-xs`}>
                Memo : <span></span>
              </p> */}
              <p className={`${Style.content}`}>
                BALANCE DUE :<span> {invoice?.balance_amount}</span>
              </p>
              <p className={`${Style.value} pro-fw-bolder`}>
                {invoice?.amount}
              </p>
            </div>
          </div>
          <div
            className={`pro-d-flex pro-py-5 pro-mx-5 ${Style.footer_wrapper}`}
          >
            {/* <button className="pro-btn pro-btn-secondary" onClick={handlePrintClick}>print</button>
            <button className="pro-btn pro-btn-secondary pro-ms-3" onClick={handleDownloadClick}>Download</button> */}

            <button
              onClick={handlePrintClick}
              className={`pro-btn-link dark pro-items-center pro-py-2 pro-px-2`}
            >
              <span className="material-symbols-outlined"> print </span>
              <span>
                <p className={`pro-fw-bold pro-mb-0 pro-ms-1`}>Print</p>
              </span>
            </button>

            <button
              onClick={handleDownloadClick}
              className={`pro-btn-link dark pro-items-center pro-py-2 pro-px-2 pro-ms-3`}
            >
              <span className="material-symbols-outlined">
                {" "}
                cloud_download{" "}
              </span>
              <span>
                <p className={`pro-fw-bold pro-mb-0 pro-ms-1`}>Download</p>
              </span>
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default InvoiceTable;

import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    try {
      const response = await api[method](
        `${endpoint}?${getParams("filter")}${getParams("sort_by")}sort_order=${
          params?.sort_order || "desc"
        }&length=${params?.length || "10"}&${getParams("search")}page=${
          params?.page || 1
        }&${getParams("faq_id")}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err?.response?.data || err.message,
      };
    }
  };

export const courses = createApi({
  reducerPath: "coursesApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Courses"],
  endpoints: (builder) => ({
    getCourseData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/course`,
      }),
      providesTags: ["Courses"],
    }),
    getCourseListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/course/list-all`,
      }),
      providesTags: ["Courses"],
    }),
    getBranchData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/v1/branch/list-all`,
      }),
      providesTags: ["Courses"],
    }),
    updateCoursesListFieldsData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/v1/table-permission-create`,
      }),

      invalidatesTags: ["Courses"],
    }),
  }),
});

export const { useGetCourseDataQuery,useUpdateCoursesListFieldsDataMutation,  useGetCourseListDataQuery,
  useGetBranchDataQuery,} = courses;

import React from "react";

const useStudentList = (studentListData) => {
  const fieldData = {
    student_id: {
      label: "Students",
      hidden: false,
      sortable: true,
      position: 2,
    },
  };

  const newData = {
    data: {
      rows: {
        data: studentListData,
      },
    },
    field: fieldData,
  };
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  return {
    newData,
    getRow,
  };
};

export default useStudentList;

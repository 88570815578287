import React from "react";
import Style from "./kenboardShimmer.module.scss";

const KenboardShimmer = ({ key }) => {
  return (
    <div className={`${Style.root} pro-p-4 pro-rounded-3 `} key={key}>
      <div className={`shimmer pro-pb-2 pro-pt-1 pro-px-6 pro-mb-2`}></div>
      <div className={`shimmer pro-py-1 pro-mb-3 ${Style.content}`}></div>
      <div className="pro-d-flex pro-justify-between pro-items-center pro-gap-3">
        <span
          className={`pro-rounded-circle shimmer pro-overflow-hidden pro-py-3 pro-px-3`}
        ></span>
        <span
          className={`pro-rounded-circle shimmer pro-overflow-hidden pro-pb-5 pro-pe-5 pro-ps-2 pro-pt-2`}
        ></span>
      </div>
    </div>
  );
};

export default KenboardShimmer;

import React, { useEffect, useRef, useState } from "react";
import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  // OffCanvasLayout,
  Table,
  // ConfirmationBox,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import useEnrolled from "./useEnrolled";
import OrderColumn from "../../Global/OrderColumn";
import EmptyData from "../../Global/EmptyData";
import Style from "./enrolled.module.scss";
// import EnquiryForm from "./EnquiryForm";
import { FaSort } from "react-icons/fa";
import KanBoardListing from "../../Global/KanBoardListing";
// import NotesCk from "../../Global/NotesCk";
// import MailCk from "../../Global/MailCk";
// import ViewNotes from "../../Global/ViewNotes";
import AvatarCardList from "../../Global/AvatarCardList";
import CustomDateRangePicker from "../../Global/CustomDateRangePicker";
import StudentsForm from "./StudentsForm";
// import TransferForm from "./TransferForm";

const Enrolled = ({ dashboard = false }) => {
  const {
    enquiry,
    enquiryStatus,
    // enquiryViewData,
    handleEditAction,
    // showDeleteConfirm,
    // setShowDeleteConfirm,
    // handleDeleteAction,
    showTooltip,
    tooltipPosition,
    // noteIsLoading,
    // isMultiSelected,
    // mailIsLoading,
    // showCreateModal,
    // hasCreatePermission,
    isFetching,
    isLoading,
    activeFilter,
    showEditModal,
    state,
    staffData,
    currentPage,
    paginationOptions,
    // showTablePopup,
    // tableRef,
    hasEditPermission,
    // hasDeletePermission,
    // optionsData,
    // notificationOptions,
    // setIsMultiSelected,
    handleSelectedIds,
    // handleRemainderUpdate,
    // handleIsnoted,
    handleViewNote,
    handleAddNote,
    handleCloseNotes,
    handleLoadMore,
    handleSendMail,
    // handleCloseMailCk,
    // handleMailCkResult,
    // handleNotesCkResult,
    handleCloseNotesCk,
    // handleTransferClose,
    updateEnquiryStatus,
    handleDateRangeChange,
    // handleDashboardRedirect,
    // handleTableFilter,
    handleDelete,
    refetch,
    // refetchStatus,
    handleSort,
    handleSearch,
    handleCreateClick,
    selectedItemsDetails,
    closeModal,
    getRow,
    handleEditClick,
    handlePagination,
    closeEditModal,
    handleDateChange,
    handlePageSize,
    updateEnquiryFields,
    handleClearClick,
    date,
  } = useEnrolled(dashboard);

  const avatarListRef = useRef(null);
  const [avatarListHeight, setAvatarListHeight] = useState();
  useEffect(() => {
    const height = avatarListRef?.current?.getBoundingClientRect()?.height;
    setAvatarListHeight(height);
  }, [showTooltip]);

  // const propertiesToInclude = ["name", "status"];

  return (
    <>
      {!dashboard ? ( 
        <>
          <HeadingGroup
            title={"Enroll"}
            // buttonTitle={hasCreatePermission ?? false ? "Add new" : ""}
            className={`pro-mb-4`}
            handleClick={handleCreateClick}
          />
        </>
      ) : (
        <></>
      )}

      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {!dashboard ? (
          <SearchFilters
            // showDateRange
            dropDownFilter={
              <div className="col-auto">
                <CustomDateRangePicker
                  defaultDateRange={date}
                  handleDateRangeChange={handleDateRangeChange}
                />
              </div>
            }
            onDateChange={handleDateChange}
            onSearchInput={handleSearch}
            showActions={activeFilter === 1 ? true : false}
            handleActionClick={handleEditClick}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            //showClearFilters
            handleClear={handleClearClick}
            initialDateRange={date}
            searchInputProps={{ value: state?.search }}
            // actionOptions={actionOptions?.filter((item) =>
            //   hasExportPermission !== 1 ? item.value === 0 : true
            // )}
            // onActionOptionChange={handleActionChange}
            // filterChildren={
            //   <div className="pro-btn-group pro-ms-auto">
            //     <button
            //       className={`pro-btn pro-btn-outline lg ${
            //         activeFilter === 1 ? "active pro-no-point" : ""
            //       }`}
            //       onClick={() => handleTableFilter(activeFilter)}
            //       title="Table View"
            //     >
            //       <span
            //         className={`material-symbols-outlined  ${Style.aling_btn}`}
            //       >
            //         format_align_left
            //       </span>
            //     </button>
            //     <button
            //       className={`pro-btn pro-btn-outline lg ${
            //         activeFilter === 2 ? "active pro-no-point" : ""
            //       }`}
            //       onClick={() => handleTableFilter(activeFilter)}
            //       title="Kanban view"
            //     >
            //       <span className="material-symbols-outlined">
            //         density_small
            //       </span>
            //     </button>
            //   </div>
            // }
          />
        ) : (
          <></>
        )}

        {enquiry?.data?.rows?.enquiries?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <>
            {activeFilter === 1 ? (
              <Table
                data={enquiry?.data?.rows?.enquiries?.data || []}
                uniqueID={"id"}
                editIcon={
                  <span className="material-symbols-outlined">edit</span>
                }
                showCheckBox={hasEditPermission ? true : false}
                // deletable={
                //   hasDeletePermission && isMultiSelected.length === 1
                //     ? true
                //     : false
                // }
                deletable={false}
                editable={false}
                handleEdit={handleEditAction}
                clear={state.clearSelection}
                multiSelect={false}
                handleSelect={handleSelectedIds}
                handleDelete={handleDelete}
                // editable={
                //   hasEditPermission && isMultiSelected.length === 1
                //     ? true
                //     : false
                // }
                assignable={true}
                assignIcon={
                  <span className="material-symbols-outlined">mail</span>
                }
                assignText={"Enroll To Students"}
                handleAssign={handleSendMail}
                fields={enquiry?.data?.field}
                SortIcon={<FaSort />}
                handleSort={handleSort}
                getRow={getRow}
                loading={isFetching}
                perpage={state?.currentPageSize}
                // propStyle={{ proTableWrp: `pro-table-wrap` }}
                // ref={tableRef}
              />
            ) : (
              <KanBoardListing
                updateData={updateEnquiryStatus}
                data={state?.queryData?.data ?? []}
                queryStatus={state?.queryStatus}
                tableStructure={enquiryStatus?.data?.enquiry_status}
                showNotes={true}
                handleLoadMore={handleLoadMore}
                handleCloseNotesCk={handleCloseNotesCk}
                handleCloseNotes={handleCloseNotes}
                handleAddNote={handleAddNote}
                handleViewNote={handleViewNote}
              />
            )}
          </>
        )}

        {enquiry?.data?.rows?.enquiries?.data?.length > 0 &&
          !dashboard &&
          activeFilter === 1 && (
            <Pagination
              currentPage={currentPage}
              totalPageCount={enquiry?.data?.rows?.enquiries?.last_page}
              defaultValue={paginationOptions?.filter(
                (item) => item.value === state?.currentPageSize
              )}
              onPageChange={handlePagination}
              options={paginationOptions}
              onActionChange={handlePageSize}
              center
            />
          )}

        <OffCanvasLayout
          show={state.showCreateModal}
          handleClose={closeModal}
          title={`Enroll to students`}
          backdrop="static"
        >
          <StudentsForm 
            refetch={refetch}
            isStickyFooter
            edit={state?.is_edit}
            // showCreateModal={showCreateModal}
            // optionsData={optionsData}
            closeModal={closeModal}
            enrollData={selectedItemsDetails}
            // enquiryViewData={enquiryViewData}
            hasEditPermission={hasEditPermission}
          />
        </OffCanvasLayout>
        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              refetch={refetch}
              tableFields={enquiry?.data?.field}
              // tableFields={Object.keys(enquiry?.data?.field ?? {})
              //   .filter((property) => !propertiesToInclude.includes(property))
              //   .reduce((filteredObj, property) => {
              //     filteredObj[property] = enquiry?.data?.field[property];
              //     return filteredObj;
              //   }, {})}
              // fixedFields={Object.keys(enquiry?.data?.field ?? {})
              //   .filter((property) => propertiesToInclude.includes(property))
              //   .reduce((filteredObj, property) => {
              //     filteredObj[property] = enquiry?.data?.field[property];
              //     return filteredObj;
              //   }, {})}
              moduleId={enquiry?.data?.table_id}
              updateData={updateEnquiryFields}
            />
          </div>
        </ModalLayout>

        {/* <ModalLayout
          show={state.showMailCk}
          handleClose={handleCloseMailCk}
          title={"Send Welcome Mail"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
          // propStyle={{ root: Style.modal_root }}
        >
          <div className="pro-m-5">
            <MailCk
              handleMailCkResult={handleMailCkResult}
              handleClose={handleCloseMailCk}
              mailIsLoading={mailIsLoading}
              isMultiSelected={isMultiSelected}
              setIsMultiSelected={setIsMultiSelected}
              UserDataOptions={enquiry?.data?.rows?.enquiries?.data}
            />
          </div>
        </ModalLayout> */}

        {/* <ModalLayout
          show={state.showNotesCk}
          handleClose={handleCloseNotesCk}
          title={"Notes"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <div className="pro-m-5">
            <NotesCk
              handleNotesCkResult={handleNotesCkResult}
              noteIsLoading={noteIsLoading}
              selectedItemData={state.selectedItemData}
              notificationOptions={notificationOptions}
            />
          </div>
        </ModalLayout> */}
        {/* <ModalLayout
          show={state.viewNotes}
          handleClose={handleCloseNotes}
          title={"Notes"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          propStyle={{ root_body: Style.modal_body }}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ViewNotes
              handleAddNote={handleAddNote}
              handleIsnoted={handleIsnoted}
              handleRemainderUpdate={handleRemainderUpdate}
              selectedItemData={state.selectedItemData}
              notificationOptions={notificationOptions}
            />
          </div>
        </ModalLayout> */}

        {/* <ModalLayout
          title={"Your Assign is transfering "}
          show={state?.showTransferModal}
          handleClose={handleTransferClose}
        >
          <div className="pro-m-5">
            <TransferForm refetch={refetch} />
          </div>
        </ModalLayout> */}

        {/* <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout> */}
        <div
          className={`
                ${Style.avatar_list_container}  
                ${showTooltip ? Style.active : ""} 
                ${
                  tooltipPosition?.placementY === "top"
                    ? Style.top
                    : Style.bottom
                }
              `}
          ref={avatarListRef}
          style={{
            "--top": tooltipPosition.y,
            "--left": tooltipPosition.x,
            "--card-height": avatarListHeight,
          }}
        >
          <AvatarCardList staffData={staffData} />
        </div>
      </div>
    </>
  );
};

export default Enrolled;

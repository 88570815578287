import {
  Button,
  IconText,
  Image,
  Input,
} from "@wac-ui-dashboard/wac_component_library";
import React, { useState } from "react";
import Select from "react-select";
import Style from "./studentsForm.module.scss";
import {
  capitalizeInitial,
  capitalizeOnSpace,
  limitStrLength,
} from "../../../../utils/functions/table";
import { format, parse } from "date-fns";
import useStudentForm from "./useStudentForm";
// import CourseModules from "./CourseModules";

const StudentsForm = ({
  refetch,
  edit,
  hasUpdatePermission,
  closeModal,
  isStickyFooter,
}) => {
  const {
    formik,
    // facultyData,
    imageCoverPreview,
    handleCoverImage,
    optionsData,
    // facultyList,
    streamData,
    courseData,
    branchData,
    dropDownData,
    selectedItemsDetails,
    profilefileInputRef,
    // moduleList,
    cuntryCodeOptions,
    nationalityData,
    // showPassword,
    // showConfirmPassword,
    // handleShowPassword,
    handleCourseChange,
    // handleShowConfirmPassword,
    handleNationalityId,
    stateRef,
    // setCourse,
    handlePhoneNumberChange,
    handleCountryId,
    options,
    selectedOption,
    handleChange,
  } = useStudentForm({ refetch, closeModal, edit });

  const states = optionsData?.data?.rows?.filter(
    (p) => p?.id === formik?.values?.country_id
  );

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuIsOpen(() => true);
  };

  const handleMenuClose = () => {
    setMenuIsOpen(() => false);
  };

  let maxDate = new Date();
  let maxDateCanChoose = maxDate;
  maxDateCanChoose.setDate(maxDate.getDate() - 1);
  const formattedMaxDate = `${maxDateCanChoose.getFullYear()}-${(
    maxDateCanChoose.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${maxDateCanChoose
    .getDate()
    .toString()
    .padStart(2, "0")}`;

  const reverseDateString = (dateString) => {
    const parts = dateString?.split("-");
    const reversedDateString = parts?.[0] + "-" + parts?.[1] + "-" + parts?.[2];
    return reversedDateString;
  };
  let dateStr = reverseDateString(formik.values.date_of_birth);
  let dateJoinStr = reverseDateString(formik.values.join_date);
  let dateStartDateStr = reverseDateString(formik.values.course_start_date);

  const handleDobChange = (e) => {
    const dateValue = e.target.value;
    const parsedDate = parse(dateValue, "yyyy-MM-dd", new Date());

    if (isNaN(parsedDate)) {
      // Handle invalid date input
      // For example, display an error message or set a flag
      return;
    }

    formik.setFieldValue("date_of_birth", format(parsedDate, "yyyy-MM-dd"));

    // Rest of the code...
  };

  const handleJoinChange = (e) => {
    const dateValue = e.target.value;
    const parsedDate = parse(dateValue, "yyyy-MM-dd", new Date());

    if (isNaN(parsedDate)) {
      // Handle invalid date input
      // For example, display an error message or set a flag
      return;
    }

    formik.setFieldValue("join_date", format(parsedDate, "yyyy-MM-dd"));

    // Rest of the code...
  };

  const handleStartDateChange = (e) => {
    const dateValue = e.target.value;
    const parsedDate = parse(dateValue, "yyyy-MM-dd", new Date());

    if (isNaN(parsedDate)) {
      // Handle invalid date input
      // For example, display an error message or set a flag
      return;
    }

    formik.setFieldValue("course_start_date", format(parsedDate, "yyyy-MM-dd"));

    // Rest of the code...
  };

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"First name*"}
        type="text"
        id="first_name
          "
        name="first_name
          "
        className={`pro-input lg ${
          formik.errors.first_name && formik.touched.first_name && "error"
        }`}
        {...formik.getFieldProps("first_name")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.first_name && formik.touched.first_name}
        errorMessage={formik.errors.first_name}
      />

      <Input
        label={"Last name*"}
        type="text"
        id="last_name
          "
        name="last_name
          "
        className={`pro-input lg ${
          formik.errors.last_name && formik.touched.last_name && "error"
        }`}
        {...formik.getFieldProps("last_name")}
        onChange={(event) => capitalizeInitial(event, formik.setFieldValue)}
        error={formik.errors.last_name && formik.touched.last_name}
        errorMessage={formik.errors.last_name}
      />

      <Input
        label={"Email address*"}
        type="text"
        id="email"
        name="email"
        placeholder="name@example.com"
        className={`pro-input lg ${
          formik.errors.email && formik.touched.email && " error"
        }`}
        {...formik.getFieldProps("email")}
        error={formik.errors.email && formik.touched.email}
        errorMessage={formik.errors.email}
      />

      <div className="input-wrap pro-mb-4">
        <div className="input-country-wrap">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Mobile number*
          </label>
          <div className="input-main-wrap verify-btn-wrap">
            <div className="code-wrap pro-mb-4 ">
              <Select
                id="dial_code"
                options={cuntryCodeOptions}
                // getOptionLabel={(option) => `${option.label}`}
                getOptionValue={(option) => `${option.value}`}
                onMenuOpen={handleMenuOpen}
                onMenuClose={handleMenuClose}
                menuIsOpen={menuIsOpen}
                formatOptionLabel={({ label, title }) =>
                  menuIsOpen ? title : label
                }
                className={` multi-select country-select ${
                  formik.touched.dial_code &&
                  formik.errors.dial_code &&
                  " error"
                }`}
                value={cuntryCodeOptions?.filter(
                  (p) =>
                    p.value?.toString() === formik.values.dial_code?.toString()
                )}
                classNamePrefix="pro-input"
                onBlur={() => formik.handleBlur("dial_code")}
                onChange={(value) =>
                  formik.setFieldValue("dial_code", value?.value)
                }
              ></Select>
            </div>
            <input
              type="text"
              id="mobile_number"
              name="mobile_number"
              className={`pro-input lg ${
                formik.errors.mobile_number &&
                formik.touched.mobile_number &&
                " error"
              }`}
              {...formik.getFieldProps("mobile_number")}
              error={
                formik.errors.mobile_number && formik.touched.mobile_number
              }
              errorMessage={formik.errors.mobile_number}
              onBlur={() => formik.handleBlur("mobile_number")}
              onChange={(e) => handlePhoneNumberChange(e.target.value)}
              // {...formik.getFieldProps("mobile_number")}
            />
          </div>
          {formik.touched.mobile_number && formik.errors.mobile_number && (
            <span className="error-text">{formik.errors.mobile_number}</span>
          )}
        </div>
      </div>

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Qualification*
        </label>
        <Select
          id="qualification_id"
          name="qualification_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.qualification_id &&
            formik.touched.qualification_id &&
            " error"
          }`}
          classNamePrefix="pro-input"
          options={dropDownData?.data?.qualifications}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={dropDownData?.data?.qualifications?.filter(
            (m) => formik.values.qualification_id === m?.id
          )}
          onChange={(value) =>
            formik.setFieldValue("qualification_id", value.id)
          }
        />
        {formik.touched.qualification_id && formik.errors.qualification_id && (
          <span className="error-text">{formik.errors.qualification_id}</span>
        )}
      </div>

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Gender*
        </label>
        <Select
          id="gender"
          name="gender"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.gender && formik.touched.gender && " error"
          }`}
          classNamePrefix="pro-input"
          onChange={handleChange}
          getOptionLabel={(option) => option?.label}
          getOptionValue={(option) => option?.value}
          value={selectedOption ? selectedOption : formik?.values?.["gender"]}
          onBlur={formik.handleBlur("gender")}
          options={options}
        />
        {formik.touched.gender && formik.errors.gender && (
          <span className="error-text">{formik.errors.gender}</span>
        )}
      </div>

      <div className="input-wrap ">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Date of Birth*
        </label>
        <div className={`pro-w-100 pro-d-flex pro-flex-column`}>
          <Input
            value={formik.values.date_of_birth === "" ? "yyyy-MM-dd" : dateStr}
            type={"date"}
            max={formattedMaxDate}
            className={`pro-input lg ${
              formik.touched.date_of_birth &&
              formik.errors.date_of_birth &&
              "error"
            }`}
            onChange={handleDobChange}
            onBlur={formik.handleBlur("date_of_birth")}
            error={formik.touched.date_of_birth && formik.errors.date_of_birth}
            errorMessage={formik.errors.date_of_birth}
          />
        </div>
      </div>

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Branch*
        </label>
        <Select
          id="branch_id"
          name="branch_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.branch_id && formik.touched.branch_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={branchData?.data}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.user?.full_name}
          value={branchData?.data?.filter(
            (m) => formik.values.branch_id === m?.id
          )}
          onChange={(value) => formik.setFieldValue("branch_id", value.id)}
        />
        {formik.touched.branch_id && formik.errors.branch_id && (
          <span className="error-text">{formik.errors.branch_id}</span>
        )}
      </div>

      {edit === false && (
        <>
          <div className={"input-wrap pro-mb-5"}>
            <label
              htmlFor="current_location"
              className="pro-font-sm pro-mb-1 pro-fw-medium"
            >
              Course*
            </label>
            <Select
              id="course_id"
              name="course_id"
              isClearable={false}
              placeholder={"Select"}
              className={`pro-input lg ${
                formik.errors.course_id && formik.touched.course_id && " error"
              }`}
              classNamePrefix="pro-input"
              options={courseData?.data}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.name}
              value={courseData?.data?.filter(
                (m) => formik.values.course_id === m?.id
              )}
              onChange={(value) => handleCourseChange(value)}
            />
            {formik.touched.course_id && formik.errors.course_id && (
              <span className="error-text">{formik.errors.course_id}</span>
            )}
          </div>
        </>
      )}
      {/* {moduleList?.length !== 0 && formik.values.course_id ? (
        <>
          <p className="pro-font-sm pro-mb-1 pro-fw-medium">{`Modules*`}</p>

          {moduleList?.map((fields, index) => {
            return (
              <CourseModules
                key={index}
                formik={formik}
                fields={fields}
                fieldIndex={index}
                moduleList={moduleList}
                facultyList={facultyList}
              />
            );
          })}
        </>
      ) : (
        ""
      )} */}
      {/* <Input
        label={"Weekly Hours*"}
        id={`weekly_hours`}
        name={`weekly_hours`}
        type="number"
        {...formik.getFieldProps(`weekly_hours`)}
        className={`pro-input lg ${
          formik.touched.weekly_hours && formik.errors.weekly_hours && " error"
        }`}
        error={formik.touched.weekly_hours && formik.errors.weekly_hours}
        errorMessage={formik.errors.weekly_hours}
      /> */}

      {/* <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Faculty
        </label>
        <Select
          id="faculty_id"
          name="faculty_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.faculty_id && formik.touched.faculty_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={facultyData?.data}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.user?.full_name}
          value={facultyData?.data?.filter(
            (m) => formik.values.faculty_id === m?.id
          )}
          onChange={(value) => formik.setFieldValue("faculty_id", value.id)}
        />
        {formik.touched.faculty_id && formik.errors.faculty_id && (
          <span className="error-text">{formik.errors.faculty_id}</span>
        )}
      </div> */}

      <div className="input-wrap ">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Course Start Date*
        </label>
        <div className={`pro-w-100 pro-d-flex pro-flex-column`}>
          <Input
            value={
              formik.values.course_start_date === ""
                ? "yyyy-MM-dd"
                : dateStartDateStr
            }
            type={"date"}
            className={`pro-input lg ${
              formik.touched.course_start_date &&
              formik.errors.course_start_date &&
              "error"
            }`}
            onChange={handleStartDateChange}
            onBlur={formik.handleBlur("course_start_date")}
            error={
              formik.touched.course_start_date &&
              formik.errors.course_start_date
            }
            errorMessage={formik.errors.course_start_date}
          />
        </div>
      </div>

      <div className="input-wrap ">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Date of Join*
        </label>
        <div className={`pro-w-100 pro-d-flex pro-flex-column`}>
          <Input
            value={formik.values.join_date === "" ? "yyyy-MM-dd" : dateJoinStr}
            type={"date"}
            min={formik.values.date_of_birth}
            className={`pro-input lg ${
              formik.touched.join_date && formik.errors.join_date && "error"
            }`}
            onChange={handleJoinChange}
            onBlur={formik.handleBlur("join_date")}
            error={formik.touched.join_date && formik.errors.join_date}
            errorMessage={formik.errors.join_date}
          />
        </div>
      </div>

      <Input
        label={"Address 1*"}
        type="text"
        id="address_one
          "
        name="address_one
          "
        className={`pro-input lg ${
          formik.errors.address_one && formik.touched.address_one && " error"
        }`}
        {...formik.getFieldProps("address_one")}
        error={formik.errors.address_one && formik.touched.address_one}
        errorMessage={formik.errors.address_one}
      />

      <Input
        label={"Address 2"}
        type="text"
        id="address_two
          "
        name="address_two
          "
        className={`pro-input lg ${
          formik.errors.address_two && formik.touched.address_two && " error"
        }`}
        {...formik.getFieldProps("address_two")}
        error={formik.errors.address_two && formik.touched.address_two}
        errorMessage={formik.errors.address_two}
      />
      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Nationality
        </label>
        <Select
          id="nationality_id"
          name="nationality_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.nationality_id &&
            formik.touched.nationality_id &&
            " error"
          }`}
          classNamePrefix="pro-input"
          options={nationalityData}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.nationality}
          value={
            Array.isArray(nationalityData)
              ? nationalityData?.filter(
                  (m) => formik.values.nationality_id === m?.id
                )
              : []
          }
          onChange={(value) => handleNationalityId(value)}
        />
        {formik.touched.nationality_id && formik.errors.nationality_id && (
          <span className="error-text">{formik.errors.nationality_id}</span>
        )}
      </div>

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          Country*
        </label>
        <Select
          id="country_id"
          name="country_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.country_id && formik.touched.country_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={optionsData?.data?.rows}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.country_name}
          value={optionsData?.data?.rows?.filter(
            (m) => formik.values.country_id === m?.id
          )}
          onChange={(value) => handleCountryId(value)}
        />
        {formik.touched.country_id && formik.errors.country_id && (
          <span className="error-text">{formik.errors.country_id}</span>
        )}
      </div>

      <div className={"input-wrap pro-mb-5"}>
        <label
          htmlFor="current_location"
          className="pro-font-sm pro-mb-1 pro-fw-medium"
        >
          State*
        </label>
        <Select
          id="state_id"
          name="state_id"
          isClearable={false}
          ref={stateRef}
          isDisabled={states?.[0]?.states ? false : true}
          placeholder={"Select"}
          className={`pro-input lg ${
            formik.errors.state_id &&
            formik.touched.state_id &&
            states?.[0]?.states &&
            " error"
          }`}
          classNamePrefix="pro-input"
          options={states?.[0]?.states}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.state_name}
          value={states?.[0]?.states?.filter(
            (m) => formik.values.state_id === m?.id
          )}
          onChange={(value) => {
            formik.setFieldValue("state_id", value.id || null);
          }}
        />
        {formik.touched.state_id &&
          formik.errors.state_id &&
          states?.[0]?.states && (
            <span className="error-text">{formik.errors.state_id}</span>
          )}
      </div>

      <Input
        label={"City*"}
        type="text"
        id="city
          "
        name="city
          "
        className={`pro-input lg ${
          formik.errors.city && formik.touched.city && " error"
        }`}
        {...formik.getFieldProps("city")}
        error={formik.errors.city && formik.touched.city}
        errorMessage={formik.errors.city}
      />

      <Input
        label={"Zipcode"}
        type="text"
        id="zipcode"
        name="zipcode
          "
        className={`pro-input lg ${
          formik.errors.zipcode && formik.touched.zipcode && " error"
        }`}
        {...formik.getFieldProps("zipcode")}
        error={formik.errors.zipcode && formik.touched.zipcode}
        errorMessage={formik.errors.zipcode}
      />
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Stream*
        </label>
        <Select
          id="stream_id"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.stream_id && formik.errors.stream_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={streamData?.rows}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          value={streamData?.rows?.filter(
            (m) => formik.values.stream_id === m?.id
          )}
          onBlur={formik.handleBlur("stream_id")}
          onChange={(value) => {
            formik.setFieldValue("stream_id", value?.id || null);
          }}
        />
        {formik.touched.stream_id && formik.errors.stream_id && (
          <span className="error-text">{formik.errors.stream_id}</span>
        )}{" "}
      </div>
      <Input
        label={"Company Name"}
        type="text"
        id="company_name
          "
        name="company_name
          "
        className={`pro-input lg ${
          formik.errors.company_name && formik.touched.company_name && "error"
        }`}
        {...formik.getFieldProps("company_name")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.company_name && formik.touched.company_name}
        errorMessage={formik.errors.company_name}
      />
      <Input
        label={"Designation"}
        type="text"
        id="designation
          "
        name="designation
          "
        className={`pro-input lg ${
          formik.errors.designation && formik.touched.designation && "error"
        }`}
        {...formik.getFieldProps("designation")}
        onChange={(event) => capitalizeOnSpace(event, formik.setFieldValue)}
        error={formik.errors.designation && formik.touched.designation}
        errorMessage={formik.errors.designation}
      />
      {edit === false && (
        <>
          <Input
            label={"Total Amount*"}
            id={`total_amount`}
            name={`total_amount`}
            type="text"
            {...formik.getFieldProps(`total_amount`)}
            className={`pro-input lg ${
              formik.touched.total_amount &&
              formik.errors.total_amount &&
              " error"
            }`}
            error={formik.touched.total_amount && formik.errors.total_amount}
            errorMessage={formik.errors.total_amount}
          />
          <Input
            label={"Received Amount*"}
            id={`received_amount`}
            name={`received_amount`}
            type="text"
            {...formik.getFieldProps(`received_amount`)}
            className={`pro-input lg ${
              formik.touched.received_amount &&
              formik.errors.received_amount &&
              " error"
            }`}
            error={
              formik.touched.received_amount && formik.errors.received_amount
            }
            errorMessage={formik.errors.received_amount}
          />
        </>
      )}
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Photo
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.touched.image && formik.errors.image && " error"
            }`}
            id="image"
            name="image"
            onBlur={formik.handleBlur("image")}
            onChange={(e) => handleCoverImage(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.image?.name !== undefined
                  ? limitStrLength(formik?.values?.image?.name, 30)
                  : selectedItemsDetails !== "" &&
                    selectedItemsDetails?.rows?.original_image_name
                  ? selectedItemsDetails?.rows?.original_image_name
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {formik.touched.image && formik.errors.image && (
            <span className="error-text">{formik.errors.image}</span>
          )}
        </div>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageCoverPreview}
            alt={`faculty - 01`}
          />
        </div>
      </div>

      <div
        className={`col-12 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className={`pro-btn-primary lg pro-ms-3 ${false ? "loading" : ""}`}
          type="submit"
          // disabled={hasUpdatePermission === true ? false : true}
          onClick={formik.handleSubmit}
        >
          {selectedItemsDetails === "" ? "Create" : "Update"}
        </Button>
      </div>
    </div>
  );
};

export default StudentsForm;

import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useCourse from "./useCourse";
// import AddFAQForm from "./AddForm";
import EmptyData from "../../Global/EmptyData";

import CouseForm from "./CourseForm";
import CourseForm from "./CourseForm";
import { ImageCard } from "../../Global/ImageCard";

const Course = () => {
  const {
    mainData,
    menuState,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    setShowDeleteConfirm,
    showDeleteConfirm,
    handleDeleteAction,
    showCreateModal,
    handleEditAction,
    updateCoursesFields,
    actionOptions,
    hasCreatePermission,
    hasDeletePermission,
    hasEditPermission,
    hasUpdatePermission,
    // hasExportPermission,
    handleActionChange,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    handleEditFAQClick,
    closeEditModal,
    showEditModal,
    handleDelete,
    selectedId,
    closeTableEditModal,
    showTableDeleteModal,
    ActionDelete,
    handleCreateClick,
    showFAQform,
    setShowFAQform,
    updateFAQFields,
    handleClearClick,
    showImageModal,
    closeModal,
    closeImageModal,
    imageData,
  } = useCourse();
  const propertiesToInclude = ["name", "status"];
  return (
    <>
      <HeadingGroup
        title={"Course"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission === true ? "Add new" : ""}
        handleClick={handleCreateClick}
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={mainData?.data?.filters}
          onDateChange={handleDateChange}
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          //showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: menuState?.search }}
          // actionOptions={actionOptions?.filter((item) =>
          //   hasExportPermission !== 1 ? item.value === 0 : true
          // )}
          // onActionOptionChange={handleActionChange}
        />
        {mainData?.data?.rows?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={mainData?.data?.rows?.data || []}
            uniqueID={"id"}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            showCheckBox={hasEditPermission ? true : false}
            //fields={adons?.data?.fields}
            deletable={hasDeletePermission === true ? true : false}
            handleDelete={handleDelete}
            handleEdit={handleEditAction}
            clear={menuState.clearSelection}
            multiSelect={false}
            assignable={false}
            fields={mainData?.data?.field}
            SortIcon={<FaSort />}
            handleSort={handleSort}
            getRow={getRow}
            loading={isFetching}
            perpage={menuState?.currentPageSize}
          />
        )}

        {mainData?.data?.rows?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={mainData?.data?.rows?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <OffCanvasLayout
          show={menuState.showCreateModal}
          handleClose={closeModal}
          title={menuState?.is_edit ? "Update Course" : "Add Courses"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <CourseForm
            refetch={refetch}
            isStickyFooter
            showCreateModal={menuState.showCreateModal}
            // optionsData={optionsData}
            closeModal={closeModal}
            // enquiryViewData={enquiryViewData}
            hasEditPermission={hasEditPermission}
          />
        </OffCanvasLayout>
        <ModalLayout show={showEditModal} handleClose={closeEditModal} backdrop="static">
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              updateData={updateCoursesFields}
              refetch={refetch}
              tableFields={Object.keys(mainData?.data?.field ?? {})
                .filter((property) => !propertiesToInclude.includes(property))
                .reduce((filteredObj, property) => {
                  filteredObj[property] = mainData?.data?.field[property];
                  return filteredObj;
                }, {})}
              moduleId={mainData?.data?.table_id}
            />
          </div>
        </ModalLayout>
        <ModalLayout show={showImageModal} handleClose={closeImageModal} backdrop="static">
          <ImageCard data={imageData} handleClose={closeImageModal} />
        </ModalLayout>
        <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
      </div>
    </>
  );
};

export default Course;

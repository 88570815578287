import { Outlet } from "react-router-dom";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import useInvoices from "./useInvoices";

const Invoices = () => {
  const { drawerMenu } = useInvoices();

  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Invoices;

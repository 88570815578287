import React, { useEffect, useMemo, useRef, useState } from "react";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import Style from "./kanBoardListingListing.module.scss";
import KanBoard from "../KanBoard";
import useKanboardListing from "./useKanboardListing";
import Assets from "../../../assets/Assets";
import KenboardShimmer from "../Shimmers/KenboardShimmer";

const KanBoardListing = ({
  data,
  showNotes = false,
  studentCourse = false,
  tableStructure,
  queryStatus,
  updateData,
  handleLoadMore,
  handleAddNote,
  handleViewNote,
  lastElement,
  refetchDataContainer,
}) => {
  const {
    characters,
    onDragEnd,
    onDragStart,
    isDropInProgress,
    removeDraggable,
  } = useKanboardListing({
    data,
    updateData,
    studentCourse,
    refetchDataContainer,
  });

  function calculateFilteredCharacters(characters, statusType) {
    return characters.filter((items) => items?.status === statusType);
  }

  const scrollRefs = useRef([]);
  const [state, setState] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  let debounceTimeout;

  useEffect(() => {
    function isScrollAtEnd(element) {
      return (
        element?.scrollTop + element?.clientHeight + 1 >= element?.scrollHeight
      );
    }

    function handleScroll() {
      const element = scrollRefs.current[state];
      setIsLoading(true);
      // if (isScrollAtEnd(element)) {
      //   handleLoadMore?.(state + 1);
      // }
      if (isScrollAtEnd(element)) {
        clearTimeout(debounceTimeout);

        debounceTimeout = setTimeout(() => {
          handleLoadMore(state + 1);
        }, 1000);
      }
    }
    const element = scrollRefs.current[state];

    element?.addEventListener("scroll", handleScroll);

    return () => {
      element?.removeEventListener("scroll", handleScroll);
    };
  }, [scrollRefs, state, handleLoadMore]);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }, [queryStatus]);

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <div
          className={`${Style.root} ${
            studentCourse ? Style.student_page : ""
          } pro-d-flex pro-w-100 pro-py-3`}
        >
          {tableStructure?.map((tableData, index) => {
            const filteredCharacters = calculateFilteredCharacters(
              characters,
              tableData?.statusType
            );

            return (
              <div className={`${Style.root_item}`} key={index}>
                <div
                  className={`${Style.item_head}
              ${
                //Style[tableData?.statusType] || ""
                tableData?.statusType === 1
                  ? Style.price_factor
                  : tableData?.statusType === 2
                  ? Style.broschre
                  : tableData?.statusType === 3
                  ? Style.demo_arrange
                  : tableData?.statusType === 4
                  ? Style.follow_up
                  : tableData?.statusType === 5
                  ? Style.lost
                  : tableData?.statusType === 6
                  ? Style.enrolled
                  : tableData?.statusType === 7
                  ? Style.lost
                  : tableData?.statusType === 8
                  ? Style.no_prospective
                  : ""
              }`}
                >
                  <p
                    className={`pro-ttl pro-mb-0 pro-font-regular pro-d-flex pro-justify-between`}
                  >
                    <span className="">{tableData?.title}</span>
                    <span className="">{tableData?.totalCount}</span>{" "}
                  </p>
                </div>

                <div className={`${Style.root_list} `} key={index}>
                  <Droppable droppableId={`column-${tableData?.statusType}`}>
                    {(provided) => (
                      <div
                        className={`${Style.root_list_item}`}
                        // ref={(ref) => {
                        //   provided.innerRef(ref);
                        //   scrollRefs.current[index] = ref;
                        // }}
                        // onMouseEnter={() => setState(index)}
                        {...provided.droppableProps}
                      >
                        <div
                          className={Style.scroll_list}
                          ref={(ref) => {
                            provided.innerRef(ref);
                            scrollRefs.current[index] = ref;
                          }}
                          onMouseEnter={() => setState(index)}
                        >
                          {characters?.length > 0
                            ? characters
                                ?.filter(
                                  (items) =>
                                    items?.status === tableData?.statusType
                                )
                                ?.map((item, itemIndex) => (
                                  <Draggable
                                    key={item.id}
                                    draggableId={`item-${item.id}`}
                                    index={itemIndex}
                                    isDragDisabled={false}
                                    // isDragDisabled={isDropInProgress}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <KanBoard
                                          badgeText={item.priority}
                                          notes={item?.notes?.length}
                                          data={item}
                                          avatar={Assets.ACCOUNT_CIRCLE}
                                          showNotes={showNotes}
                                          title={item.name ?? item.title}
                                          subTitle={
                                            studentCourse
                                              ? ""
                                              : `${item.phone_number}`
                                          }
                                          handleAddNote={handleAddNote}
                                          handleViewNote={handleViewNote}
                                          extraClassName={
                                            studentCourse
                                              ? Style.studentCourse_board
                                              : ""
                                          }
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))
                            : [...Array(6)].map((_, i) => (
                                <KenboardShimmer key={i} />
                              ))}

                          {provided.placeholder}
                          {filteredCharacters?.length < tableData?.totalCount &&
                            isLoading &&
                            index === state &&
                            [...Array(3)].map((_, i) => (
                              <>
                                <KenboardShimmer key={i} />
                              </>
                            ))}

                          {/* {(queryStatus === "pending" && index === state) ||
                          (isLoading && index === state) ? (
                            [...Array(3)].map((_, i) => (
                              <KenboardShimmer key={i} />
                            ))
                          ) : (
                            <></>
                          )} */}
                        </div>
                      </div>
                    )}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </div>
      </DragDropContext>
    </>
  );
};

export default KanBoardListing;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  transferNote: "",
  IDs: {},
  selectedItemsDetails: "",
  phoneVerified: {
    verified: false,
    status: false,
  },
  is_edit: false,
  is_active: 1,
};

export const getSubAdminDataByID = createAsyncThunk(
  "v1/sub-admin/show",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/sub-admin/show/${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createSubAdmin = createAsyncThunk(
  "sub-admin/store",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/sub-admin/store", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const viewSubAdminProfile = createAsyncThunk(
  "subAdmin/viewSubAdminProfile",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/v1/users/profile`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateSubAdmin = createAsyncThunk(
  "sub-admin/update",
  async ({ id, data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/sub-admin/update/${id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSubAdmin = createAsyncThunk(
  "sub-admin/delete",
  async (Id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/sub-admin/delete/${Id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const passwordReset = createAsyncThunk(
  "subAdmin/passwordReset",
  async (formdata, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/v1/sub-admin/change-password`,
        formdata
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const subAdminsSlice = createSlice({
  name: "subAdmins",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubAdminDataByID.pending, (state) => {
        state.isLoading = true;
        state.selectedItemsDetails = {}
        state.error = null;
      })
      .addCase(getSubAdminDataByID.fulfilled, (state, action) => {
        state.selectedItemsDetails = action.payload.data.data;
      })
      .addCase(getSubAdminDataByID.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(viewSubAdminProfile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(viewSubAdminProfile.fulfilled, (state, action) => {
        
        state.profileDetails = action.payload.data.data;
      })
      .addCase(viewSubAdminProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig } = subAdminsSlice.actions;

export default subAdminsSlice.reducer;

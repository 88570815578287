import React from "react";
import useEditPaymentForm from "./useEditPaymentForm";
import { Button, Input } from "@wac-ui-dashboard/wac_component_library";

const EditPaymentForm = ({ refetch, closeModal, selectedId }) => {
  const { formik } = useEditPaymentForm(selectedId, closeModal, refetch);
  return (
    <div>
      <div className="row">
        <div className="col-12 pro-mb-4">
          <div className="input-wrap">
            <Input
              id="total_amount"
              name="total_amount"
              label="Total Amount*"
              className={`pro-input lg ${
                formik.touched.total_amount &&
                formik.errors.total_amount &&
                "error"
              }`}
              value={formik?.values?.total_amount}
              error={formik.touched.total_amount && formik.errors.total_amount}
              errorMessage={formik.errors.total_amount}
              {...formik.getFieldProps("total_amount")}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 pro-mb-4">
          <div className="input-wrap">
            <Input
              id="paid_amount"
              name="paid_amount"
              label="Paid Amount*"
              className={`pro-input lg ${
                formik.touched.paid_amount &&
                formik.errors.paid_amount &&
                "error"
              }`}
              value={formik?.values?.paid_amount}
              error={formik.touched.paid_amount && formik.errors.paid_amount}
              errorMessage={formik.errors.paid_amount}
              {...formik.getFieldProps("paid_amount")}
            />
          </div>
        </div>
      </div>

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button className={"pro-btn-link lg pro-px-5"} onClick={closeModal}>
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          onClick={formik?.handleSubmit}
        >
          {true ? (
            <i
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></i>
          ) : (
            ""
          )}
          Update Payment
        </Button>
      </div>
    </div>
  );
};

export default EditPaymentForm;

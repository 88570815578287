import React from "react";
import Style from "./timeTableTitle.module.scss";

const TimeTableTitle = ({ isVertical, isEmptyTitle, time }) => {
  return !isEmptyTitle ? (
    <div className={`${Style.root} ${isVertical && Style.vertical}`}>
      <h6 className={`pro-mb-0 ${Style.title}`}>{time}</h6>
    </div>
  ) : (
    <div className={Style.root_empty}></div>
  );
};

export default TimeTableTitle;

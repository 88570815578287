import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useTimeScheduleForm from "./useTimeScheduleForm";
import TimeSlotEntries from "./TimeSlotEntries";
import Style from "./TimeSlotEntries/timeSlotEntries.module.scss";

const TimeScheduleForm = ({ studentData, studentId, courseRefetch }) => {
  const {
    formik,
    facultyObj,
    facultyList,
    courseList,
    studentList,
    daysList,
    moduleList,
    moduleObj,
    courseObj,
    timeSlotList,
    dateStartDateStr,
    handleAddField,
    handleFacultyChange,
    handleCloseModal,
    handleStartDateChange,
  } = useTimeScheduleForm({ studentData, studentId, courseRefetch });

  return (
    <>
      <div className="row">
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Faculty
          </label>
          <Select
            id="faculty_id"
            isClearable={false}
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik.touched.faculty_id && formik.errors.faculty_id && " error"
            }`}
            classNamePrefix="pro-input"
            options={facultyList}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={facultyObj}
            onBlur={formik.handleBlur("faculty_id")}
            onChange={(value) => handleFacultyChange(value)}
          />
          {formik.touched.faculty_id && formik.errors.faculty_id && (
            <span className="error-text">{formik.errors.faculty_id}</span>
          )}{" "}
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Course
          </label>
          <Select
            id="course_id"
            isClearable={false}
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik.touched.course_id && formik.errors.course_id && " error"
            }`}
            classNamePrefix="pro-input"
            options={courseList}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={courseObj}
            onBlur={formik.handleBlur("course_id")}
            onChange={(value) => {
              formik.setFieldValue("course_id", value?.value ?? "");
            }}
            isDisabled
          />
          {formik.touched.course_id && formik.errors.course_id && (
            <span className="error-text">{formik.errors.course_id}</span>
          )}{" "}
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Course Module
          </label>
          <Select
            id="course_module_id"
            isClearable={false}
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik.touched.course_module_id &&
              formik.errors.course_module_id &&
              " error"
            }`}
            classNamePrefix="pro-input"
            options={moduleList}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={moduleObj}
            onBlur={formik.handleBlur("course_module_id")}
            onChange={(value) => {
              formik.setFieldValue("course_module_id", value?.value ?? "");
            }}
            isDisabled
          />
          {formik.touched.course_module_id &&
            formik.errors.course_module_id && (
              <span className="error-text">
                {formik.errors.course_module_id}
              </span>
            )}{" "}
        </div>
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Student
          </label>
          <Select
            id="student_id"
            isClearable={false}
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik.touched.student_id && formik.errors.student_id && " error"
            }`}
            classNamePrefix="pro-input"
            options={studentList}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={studentList}
            onBlur={formik.handleBlur("student_id")}
            onChange={(value) => {
              formik.setFieldValue("student_id", value?.value ?? "");
            }}
            isDisabled
          />
          {formik.touched.student_id && formik.errors.student_id && (
            <span className="error-text">{formik.errors.student_id}</span>
          )}{" "}
        </div>
        <div className="input-wrap ">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Module Start Date*
          </label>
          <div className={`pro-w-100 pro-d-flex pro-flex-column`}>
            <Input
              value={
                formik.values.module_start_date === ""
                  ? "yyyy-MM-dd"
                  : dateStartDateStr
              }
              type={"date"}
              className={`pro-input lg ${
                formik.touched.module_start_date &&
                formik.errors.module_start_date &&
                "error"
              }`}
              onChange={handleStartDateChange}
              onBlur={formik.handleBlur("module_start_date")}
              error={
                formik.touched.module_start_date &&
                formik.errors.module_start_date
              }
              errorMessage={formik.errors.module_start_date}
            />
          </div>
        </div>
        <Input
          label={"Weekly Hours*"}
          id={`weekly_hours`}
          name={`weekly_hours`}
          type="text"
          {...formik.getFieldProps(`weekly_hours`)}
          className={`pro-input lg ${
            formik.touched.weekly_hours &&
            formik.errors.weekly_hours &&
            " error"
          }`}
          error={formik.touched.weekly_hours && formik.errors.weekly_hours}
          errorMessage={formik.errors.weekly_hours}
        />
        <div className={Style.address}>
          {formik.values.timetable_entries?.map((fields, index) => {
            return (
              <TimeSlotEntries
                key={index}
                formik={formik}
                fields={fields}
                fieldIndex={index}
                daysList={daysList}
                timeSlotList={timeSlotList}
                handleAddField={handleAddField}
              />
            );
          })}

          <Button
            className={`pro-btn-primary lg pro-w-100 pro-mt-4`}
            onClick={handleAddField}
          >
            {` Add Another`}
          </Button>
        </div>
        <div
          className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>

          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            type="submit"
            onClick={formik.handleSubmit}
          >
            {`Assign`}
          </Button>
        </div>
      </div>
    </>
  );
};

export default TimeScheduleForm;

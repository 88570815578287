import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useProfileEdit from "./useProfileEdit";
import AddFormFields from "../AddFormFields";

const ProfileDetails = ({
  setShowform,
  refetch,
  userData,
  isStickyFooter,
  setProfileViewVisible,
}) => {
  const {
    profilefileInputRef,
    formikEditUserData,
    formikEditPassword,
    loading,
    optionsWithIcons,
    staffList,
    selectedOption,
    showExistingPassword,
    showNewPassword,
    imagePreview,
    cuntryCodeOptions,
    handlePhoneNumberChange,
    handleProfileImage,
    handleShowExistingPassword,
    handleShowNewPassword,
    setSelectedOption,
    options,
    handleFileChange,
    isDataLoading,
    setIsDataLoading,
    // userData,
  } = useProfileEdit({ setProfileViewVisible, refetch,userData });

  return (
    <div className={`pro-w-100`}>
      <AddFormFields
        optionsWithIcons={optionsWithIcons}
        imagePreview={imagePreview}
        handleProfileImage={handleProfileImage}
        handlePhoneNumberChange={handlePhoneNumberChange}
        cuntryCodeOptions={cuntryCodeOptions}
        staffList={staffList}
        formikEditUserData={formikEditUserData}
        formikEditPassword={formikEditPassword}
        setShowform={setShowform}
        selectedOption={selectedOption}
        profilefileInputRef={profilefileInputRef}
        setSelectedOption={setSelectedOption}
        options={options}
        handleFileChange={handleFileChange}
        isDataLoading={isDataLoading}
        setIsDataLoading={setIsDataLoading}
        userData={userData}
        setProfileViewVisible={setProfileViewVisible}
        showExistingPassword={showExistingPassword}
        showNewPassword={showNewPassword}
        handleShowExistingPassword={handleShowExistingPassword}
        handleShowNewPassword={handleShowNewPassword}
      />
      <div
        className={`col-12 pro-mt-4 pro-d-flex pro-justify-end ${
          isStickyFooter && "offcanvas-footer-sticky-btns"
        }`}
      >
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => setProfileViewVisible(true)}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          onClick={formikEditPassword.handleSubmit}
          type="submit"
        >
          {loading ? (
            <i
              className="spinner-border spinner-border-sm me-3"
              role="status"
              aria-hidden="true"
            ></i>
          ) : (
            ""
          )}
          Update
        </Button>
      </div>
    </div>
  );
};

export default ProfileDetails;

import { useFormik } from "formik";
import * as Yup from "yup";
import { format, parse } from "date-fns";
import {
  updateConfig,
  //   updateEditCourseData,
} from "../../../../store/slices/Students/studentsProfileSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { updateEditCourseData } from "../../StudentsListing/api";

const useEditForm = () => {
  const dispatch = useDispatch();
  const { editCourseData, editSelectedId } = useSelector(
    (state) => state.studentProfile
  );

  useEffect(() => {
    if (editCourseData !== "") {
      formik.setValues({
        module_start_date: editCourseData?.module_start_date ?? "",
        weekly_hours: editCourseData?.weekly_hours ?? "",
      });
    }
    //eslint-disable-next-line
  }, [editCourseData]);
  const formik = useFormik({
    initialValues: {
      module_start_date: "",
      weekly_hours: "",
    },
    validationSchema: Yup.object({
      module_start_date: Yup.string().required("*Module start date Required"),
      weekly_hours: Yup.string().trim().required("*Weekly hours Required"),
    }),
    onSubmit: (values) => {
      let params = {
        id: editSelectedId,
        ...values,
      };
      updateEditCourseData(params).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success(`Details updated successfully`);
          closeModal();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        } else {
          toast.error(`Something went wrong!`);
          closeModal();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        }
      });
    },
  });
  const reverseDateString = (dateString) => {
    const parts = dateString?.split("-");
    const reversedDateString = parts?.[0] + "-" + parts?.[1] + "-" + parts?.[2];
    return reversedDateString;
  };
  let dateStartDateStr = reverseDateString(formik.values.module_start_date);
  const handleStartDateChange = (e) => {
    const dateValue = e.target.value;
    const parsedDate = parse(dateValue, "yyyy-MM-dd", new Date());

    if (isNaN(parsedDate)) {
      // Handle invalid date input
      // For example, display an error message or set a flag
      return;
    }

    formik.setFieldValue("module_start_date", format(parsedDate, "yyyy-MM-dd"));

    // Rest of the code...
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showEditModal = !state.showEditModal;
      })
    );
  };
  return { formik, dateStartDateStr, handleStartDateChange, closeModal };
};

export default useEditForm;

import Dropdown from "react-bootstrap/Dropdown";
import Style from "./HeaderActions.module.scss";
import useHeaderActions from "./useHeaderActions";
import {
  ModalLayout,
  OffCanvasLayout,
} from "@wac-ui-dashboard/wac_component_library";
import GlobalSearch from "../../../../components/Global/GlobalSearch";
import Notification from "../../../../components/Global/Notification";
import ProfileDetails from "../../../../components/Global/ProfileView/AddForm";

const HeaderActions = () => {
  const {
    isModalOpen,
    userData,
    searchInputRef,
    globalState,
    subAdminState,
    handleInputChange,
    lastElement,
    setIsModalOpen,
    closeModal,
    handleSearchData,
    handleLogout,
    handleSettings,
    handleViewProfile,
    handleProfileRedirect,
    handleCloseProfileView,
    isProfileViewVisible,
    setProfileViewVisible,
    notification,
    isNewNotification,
    handleReadNotification,
    handleNotificationRedirect,
    isFetching,
  } = useHeaderActions();

  return (
    <div className={`pro-ms-auto pro-d-flex pro-items-center ${Style.root}`}>
      {/* <div className={`input-wrap pro-me-4 ${Style.input_wrap}`} title="ctrl+k">
        <input
          placeholder="Search"
          onClick={() => setIsModalOpen(true)}
          onChange={(e) => handleInputChange(e.target.value)}
          className="pro-input icon-l icon-r lg"
        />
        <span className="pro-icon">
          <span className="material-symbols-outlined"> search </span>
        </span>
        <span className={`${Style.key_icon} pro-d-flex`}>
          <span className={`material-symbols-outlined`}>
            keyboard_command_key
          </span>
          <span>K</span>
        </span>
      </div> */}
      <Notification
        data={notification?.rows?.data}
        isFetching={isFetching}
        isNewNotification={isNewNotification}
        // handleRedirect={handleRedirect}
        handleReadNotification={handleReadNotification}
        handleNotificationRedirect={handleNotificationRedirect}
      />
      <Dropdown>
        <Dropdown.Toggle id="profile-dropdown">
          <div className="pro-avatar lg pro-flex-shrink-0">
            {subAdminState?.profileDetails?.image_url ? (
              <img
                src={subAdminState?.profileDetails?.image_url}
                alt="staff_image"
              />
            ) : (
              <span
                className={`material-symbols-outlined x4 ${Style.profile_icon}`}
              >
                {" "}
                account_circle{" "}
              </span>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={handleViewProfile}
            className="pro-d-flex pro-items-center"
          >
            <span className="material-symbols-outlined x3">
              {" "}
              account_circle{" "}
            </span>
            <span className="pro-ms-2">Profile</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={()=>handleSettings()}
            className="pro-d-flex pro-items-center"
          >
            <span className="material-symbols-outlined x3"> settings </span>
            <span className="pro-ms-2">Settings</span>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={handleLogout}
            className="pro-d-flex pro-items-center"
          >
            <span className="material-symbols-outlined x3"> logout </span>
            <span className="pro-ms-2">Logout</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <OffCanvasLayout
        show={globalState.isProfileViewVisible}
        handleClose={handleCloseProfileView}
        title={"Profile"}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
        backdrop="static"
      >
        <ProfileDetails
          setProfileViewVisible={setProfileViewVisible}
          isStickyFooter
          userData={subAdminState?.profileDetails}
        />
      </OffCanvasLayout>

      <ModalLayout handleClose={closeModal} show={isModalOpen} backdrop="static">
        <GlobalSearch
          handleSearchData={handleSearchData}
          lastElement={lastElement}
          searchKey={globalState.searchKey}
          userData={userData}
          ref={searchInputRef}
          handleProfileRedirect={handleProfileRedirect}
        />
      </ModalLayout>
    </div>
  );
};

export default HeaderActions;

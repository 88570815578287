import React, { useEffect, useState } from "react";
//import "../../../assets/scss/date-rang-picker.scss";
import "../../../assets/scss/date-rang-picker-3.8.12.scss";
import { DateRangePicker } from "rsuite";
import predefinedRanges from "./predefinedDates";
import {
  getFormatedDate,
  getFormatedDateRange,
} from "../../../utils/functions/table";
// import moment from "moment";

const CustomDateRangePicker = ({ defaultDateRange, handleDateRangeChange }) => {
  const [dateRange, setDateRange] = useState(defaultDateRange);

  const handleSelect = (selected) => {
    setDateRange({ endDate: defaultDateRange?.endDate, startDate: selected });
  };

  const renderTitle = (date) => {
    const monthYear = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "short",
    }).format(date);
    return <div>{monthYear}</div>;
  };

  const renderValue = (selectedDates, format) => {
    return `${getFormatedDateRange(
      defaultDateRange?.startDate
    )} - ${getFormatedDateRange(defaultDateRange?.endDate)}`;
  };

  const customLocale = {
    sunday: "Su",
    monday: "Mo",
    tuesday: "Tu",
    wednesday: "We",
    thursday: "Th",
    friday: "Fr",
    saturday: "Sa",
    ok: "Apply",
    today: "Today",
    yesterday: "Yesterday",
    last7Days: "Last 7 Days",
    thisMonth: "This Month",
    lastMonth: "Last Month",
  };

  // function formatDate(inputDate) {
  //   // Parse the input date using Moment.js
  //   const date = moment(inputDate, "YYYY-MM");

  //   // Format the date as "MMM YYYY"
  //   const formattedDate = date.format("MMM YYYY");

  //   return formattedDate;
  // }
  // useEffect(() => {
  //   // Find the month dropdown title element and update its content
    
  // }, []);

  
  //   const monthDropdownTitle = document.querySelectorAll(
  //     ".rs-calendar-header-title-date"
  //   );
  //   if (monthDropdownTitle) {
  //     monthDropdownTitle.forEach((item, index) => {
  //       item?.addEventListener("DOMNodeRemoved", (e)=>{
  //       })
  //       item?.addEventListener("DOMNodeInserted", (e)=>{
  //       })
  //       index === 0
  //         ? (item.textContent = formatDate(item.innerHTML))
  //         : (item.textContent = formatDate(item.innerHTML));
  //     });
  //   }
  

  return (
    // <DateRangePicker
    //   format="dd-MM-yyyy"
    //   ranges={predefinedRanges}
    //   character=" - "
    //   style={{ width: "100%" }}
    //   locale={customLocale}
    //   value={[defaultDateRange?.startDate, defaultDateRange?.endDate]}
    //   onChange={handleDateRangeChange}
    // />
    <DateRangePicker
      ranges={predefinedRanges}
      format="DD MMM YYYY"
      renderValue={renderValue}
      onSelect={handleSelect}
      // character=" - "
      style={{ width: "100%" }}
      locale={customLocale}
      // value={[defaultDateRange?.startDate, defaultDateRange?.endDate]}
      defaultValue={[dateRange?.startDate, dateRange?.endDate]}
      onChange={handleDateRangeChange}
      cleanable={false}
    />
  );
};

export default CustomDateRangePicker;
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";

// import AddFAQForm from "./AddForm";
import EmptyData from "../../Global/EmptyData";
import useActionLog from "./useActionLog";

const ActionLog = () => {
  const {
    mainData,
    menuState,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    actionOptions,
    hasCreatePermission,
    hasDeletePermission,
    hasEditPermission,
    hasUpdatePermission,
    hasExportPermission,
    handleActionChange,
    refetch,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditClick,
    handleEditFAQClick,
    closeEditModal,
    showEditModal,
    handleDelete,
    closeTableEditModal,
    showTableDeleteModal,
    ActionDelete,
    showFAQform,
    setShowFAQform,
    updateFAQFields,
    handleClearClick,
    showImageModal,
    closeModal,
    closeImageModal,
		imageData
  } = useActionLog();

  return (
    <>
      <HeadingGroup
        title={"Action Logs"}
        className={`pro-mb-4`}
      
      />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
    
        {mainData?.data?.rows?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={mainData?.data?.rows?.data || []}
            uniqueID={"_id"}
            fields={mainData?.data?.field}
          
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
          
            clear={menuState.clearSelection}
            multiSelect={false}
            loading={isFetching}
            perpage={menuState.currentPageSize}
            editable={hasEditPermission === true ? true : false}
            assignable={false}
            handleEdit={handleEditFAQClick}
            handleDelete={handleDelete}
            deletable={hasDeletePermission === true ? true : false}
          />
        )}

        {mainData?.data?.rows?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={mainData?.data?.rows?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
         )} 

      

        
      </div>
    </>
  );
};

export default ActionLog;

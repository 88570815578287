import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { storeTimeSchedule } from "../../../TimeTables/TimeTable/api";
import {
  useGetDaysListDataQuery,
  useGetFacultyListDataQuery,
  useGetTimeSlotListDataQuery,
  // useGetTimeTableListDataQuery,
} from "../../../../store/queries/timeTable";
import { updateConfig } from "../../../../store/slices/Students/studentsProfileSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useGetCourseDataQuery } from "../../../../store/queries/students";
import { getCourseModuleList } from "../../../../store/slices/Students/studentsSlice";
import { useSelector } from "react-redux";
import { timeTable } from "../../../../store/queries/timeTable";
import { format, parse } from "date-fns";

const useTimeScheduleForm = ({ studentData, studentId, courseRefetch }) => {
  const { courseModuleList } = useSelector((state) => state.students);
  const dispatch = useDispatch();
  let selectedStudent = studentData?.rows?.Student?.filter(
    (item) => item?.id === studentId
  );
  let { student_id, student_name } = { ...studentData?.rows };
  let studentList = {
    label: student_name,
    value: student_id,
  };
  // const {
  //   data: timeTableData = {},
  //   // isFetching,
  //   // isSuccess,
  //   isLoading,
  //   refetch,
  // } = useGetTimeTableListDataQuery({
  //   faculty: selectedStudent?.[0]?.faculty_id,
  //   length: "",
  //   page: "",
  // });
  const [fieldIndex, setFieldIndex] = useState(0);
  useEffect(() => {
    formik.setValues({
      ...formik.values,
      faculty_id: selectedStudent?.[0]?.faculty_id,
      course_id: selectedStudent?.[0]?.course_id,
      course_module_id: selectedStudent?.[0]?.course_module_id,
      student_id: student_id,
    });
    //eslint-disable-next-line
  }, [studentId]);

  useEffect(() => {
    if (selectedStudent?.[0]?.course_id) {
      dispatch(getCourseModuleList(selectedStudent?.[0]?.course_id));
    }
    //eslint-disable-next-line
  }, [selectedStudent?.[0]?.course_id]);

  const [initialData, setInitialData] = useState({
    faculty_id: "",
    course_id: "",
    course_module_id: "",
    student_id: "",
    module_start_date: "",
    weekly_hours: "",
    timetable_entries: [{ day_id: "", time_slot_ids: [] }],
  });
  const { data: facultyData } = useGetFacultyListDataQuery();
  const { data: daysListData } = useGetDaysListDataQuery();
  const { data: timeSlotListData } = useGetTimeSlotListDataQuery();
  const { data: courseListData } = useGetCourseDataQuery();

  const facultyList = facultyData?.data?.map(({ user: { full_name }, id }) => ({
    label: full_name,
    value: id,
  }));
  const daysList = daysListData?.data?.map(({ day, id }) => ({
    label: day,
    value: id,
  }));
  const courseList = courseListData?.data?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const moduleList = courseModuleList?.map(({ title, id }) => ({
    label: title,
    value: id,
  }));
  const validation = Yup.object({
    faculty_id: Yup.string().required("Choose Faculty"),
    course_id: Yup.string().required("Enter Course"),
    course_module_id: Yup.string().required("Enter Course Module"),
    student_id: Yup.string().required("Select Students"),
    module_start_date: Yup.string().required("*Module start date Required"),
    weekly_hours: Yup.string().trim().required("*Weekly hours Required"),
    timetable_entries: Yup.array().of(
      Yup.object().shape({
        day_id: Yup.string().required("Select Day"),
        time_slot_ids: Yup.array()
          // .of(
          //   Yup.string().test(
          //     "Time slots available",
          //     "Selected time slots is already booked",
          //     function (slotValues) {
          //       let hasBookedSlots = false;
          //       for (const value of slotValues) {
          //         // for (const selectedDay of formik?.values?.course?.day_id) {
          //         let dayObject = daysList?.filter(
          //           // eslint-disable-next-line
          //           (item) =>
          //             item?.value ===
          //             formik?.values?.timetable_entries?.[fieldIndex]?.day_id
          //         );
          //         if (
          //           timeTableData?.data?.slot_assigned &&
          //           timeTableData?.data?.slot_assigned[`slot${value}`]
          //         ) {
          //           const slotData =
          //             timeTableData?.data?.slot_assigned[`slot${value}`];
          //           if (
          //             slotData[dayObject?.[0]?.label] &&
          //             slotData[dayObject?.[0]?.label]?.data &&
          //             slotData[dayObject?.[0]?.label]?.data?.length > 0
          //           ) {
          //             let courseObj = moduleList?.filter(
          //               // eslint-disable-next-line
          //               (item) =>
          //                 item?.value === formik?.values?.course_module_id
          //             );
          //             console.log(
          //               "33333333333333333333",
          //               moduleList,
          //               courseObj,
          //               slotData[dayObject?.[0]?.label]?.data?.[0]
          //                 ?.course_module_name
          //             );
          //             if (
          //               slotData[dayObject?.[0]?.label]?.data?.[0]
          //                 ?.course_module_name !== courseObj?.[0]?.label
          //             ) {
          //               console.log("44444444444");
          //               hasBookedSlots = true;
          //               break; // Stop checking if any slot is booked
          //               // }
          //             }
          //           }
          //         }
          //         // }
          //       }
          //       return !hasBookedSlots; // Return true if no slot is booked
          //     }
          //   )
          // )
          .min(1, "Select Time Slots")
          .required("Select Time Slots"),
      })
    ),
  });

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      storeTimeSchedule(values).then((response) => {
        if (response?.data?.status_code === 200) {
          handleCloseModal();
          dispatch(
            timeTable.util.invalidateTags(["TimeTable", "TimeSchedule"])
          );
          courseRefetch();
          toast.success(`Time Scheduled`);
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        } else if (response?.data?.status_code === 422) {
          toast.error(response?.data?.message);
          handleCloseModal();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        } else {
          toast.error(response?.message);
          handleCloseModal();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        }
      });
    },
  });

  const handleFacultyChange = (value) => {
    // setFacultyId(value?.value);
    formik.setFieldValue("faculty_id", value?.value ?? "");
  };
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAssignModal = false;
      })
    );
  };
  const handleAddField = () => {
    setFieldIndex(fieldIndex + 1);
    let { timetable_entries } = formik.values;
    timetable_entries = [
      ...timetable_entries,
      { day_id: "", time_slot_ids: [] },
    ];
    formik.setFieldValue("timetable_entries", timetable_entries);
  };
  let facultyObj = facultyList?.filter(
    (item) => item?.value === formik?.values?.faculty_id
  );
  let courseObj = courseList?.filter(
    (item) => item?.value === formik?.values?.course_id
  );
  let moduleObj = moduleList?.filter(
    (item) => item?.value === formik?.values?.course_module_id
  );
  const reverseDateString = (dateString) => {
    const parts = dateString?.split("-");
    const reversedDateString = parts?.[0] + "-" + parts?.[1] + "-" + parts?.[2];
    return reversedDateString;
  };
  let dateStartDateStr = reverseDateString(formik.values.module_start_date);
  const handleStartDateChange = (e) => {
    const dateValue = e.target.value;
    const parsedDate = parse(dateValue, "yyyy-MM-dd", new Date());

    if (isNaN(parsedDate)) {
      // Handle invalid date input
      // For example, display an error message or set a flag
      return;
    }

    formik.setFieldValue("module_start_date", format(parsedDate, "yyyy-MM-dd"));

    // Rest of the code...
  };

  return {
    formik,
    daysList,
    courseList,
    facultyObj,
    moduleObj,
    selectedStudent,
    courseObj,
    moduleList,
    studentList,
    facultyList,
    dateStartDateStr,
    timeSlotList: timeSlotListData?.data,
    setFieldIndex,
    handleAddField,
    handleCloseModal,
    handleFacultyChange,
    handleStartDateChange,
  };
};

export default useTimeScheduleForm;

import { Outlet } from "react-router-dom";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import useEnquiries from "./useEnquires";

const Enquires = () => {
  const { drawerMenu } = useEnquiries();

  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Enquires;

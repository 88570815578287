import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  showCreateModal: false,
  receivedAmount: "",
  transactionId: "",
  IDs: {},
  netPayableAmount: "",
  showPaymentModal: false,
  showInvoiceModal: false,
  invoiceLoading: false,
  selectedItemsDetails: "",
  editData: {},
};

const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = invoicesSlice.actions;

export default invoicesSlice.reducer;

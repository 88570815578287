import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import OrderColumn from "../../Global/OrderColumn";
import useInvoice from "./useInvoice";
// import AddFAQForm from "./AddForm";
import EmptyData from "../../Global/EmptyData";
import InvoiceTable from "../InvoiceTable";
import Style from "./invoice.module.scss";
import AddPaymentForm from "../AddPaymentForm";
import EditPaymentForm from "../EditPaymentForm";

const Invoice = () => {
  const {
    mainData,
    menuState,
    updateInvoiceFields,
    currentPage,
    paginationOptions,
    isFetching,
    isLoading,
    selectedId,
    refetch,
    closeModal,
    handlePaymentClose,
    // hasExportPermission,
    closeInvoiceModal,
    handlePagination,
    handlePageSize,
    handleDateChange,
    handleSort,
    handleSearch,
    getRow,
    handleEditAction,
    handleEditClick,

    closeEditModal,
    showEditModal,

    handleClearClick,
  } = useInvoice();
  const propertiesToInclude = ["name", "status"];

  return (
    <>
      <HeadingGroup title={"Payment"} className={`pro-mb-4`} />
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        <SearchFilters
          data={mainData?.data?.filters}
          onDateChange={handleDateChange}
          onSearchInput={handleSearch}
          showActions={true}
          handleActionClick={handleEditClick}
          loading={isLoading}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          //showClearFilters
          handleClear={handleClearClick}
          searchInputProps={{ value: menuState?.search }}
          // actionOptions={actionOptions?.filter((item) =>
          //   hasExportPermission !== true ? item.value === 0 : true
          // )}
          // onActionOptionChange={handleActionChange}
        />

        {mainData?.data?.rows?.data?.length === 0 ? (
          <EmptyData />
        ) : (
          <Table
            data={mainData?.data?.rows?.data || []}
            uniqueID={"id"}
            fields={mainData?.data?.field}
            showCheckBox={true}
            SortIcon={<FaSort />}
            editIcon={<span className="material-symbols-outlined">edit</span>}
            deleteIcon={
              <span className="material-symbols-outlined">delete</span>
            }
            handleSort={handleSort}
            getRow={getRow}
            clear={menuState.clearSelection}
            multiSelect={false}
            editable={true}
            handleEdit={handleEditAction}
            deletable={false}
            loading={isFetching}
            perpage={menuState.currentPageSize}
            assignable={false}
          />
        )}

        {mainData?.data?.rows?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === menuState?.currentPageSize
            )}
            totalPageCount={mainData?.data?.rows?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}

        <ModalLayout
          show={showEditModal}
          handleClose={closeEditModal}
          backdrop="static"
        >
          <div className="pro-m-5">
            <OrderColumn
              title={"Choose which columns you see"}
              // refetchData={refetch}
              updateData={updateInvoiceFields}
              tableFields={Object.keys(mainData?.data?.field ?? {})
                .filter((property) => !propertiesToInclude.includes(property))
                .reduce((filteredObj, property) => {
                  filteredObj[property] = mainData?.data?.field[property];
                  return filteredObj;
                }, {})}
              moduleId={mainData?.data?.table_id}
            />
          </div>
        </ModalLayout>

        <ModalLayout
          title={"Add Received Amount "}
          show={menuState?.showPaymentModal}
          handleClose={handlePaymentClose}
        >
          <div className="pro-m-5">
            <AddPaymentForm refetch={refetch} />
          </div>
        </ModalLayout>
        <ModalLayout
          show={menuState.showInvoiceModal}
          propStyle={{ root: Style.modal_root }}
          handleClose={closeInvoiceModal}
          centered={false}
        >
          <InvoiceTable />
        </ModalLayout>

        <ModalLayout
          title={"Edit Amount "}
          show={menuState?.showCreateModal}
          handleClose={closeModal}
        >
          <div className="pro-m-5">
            <EditPaymentForm
              refetch={refetch}
              closeModal={closeModal}
              selectedId={selectedId}
            />
          </div>
        </ModalLayout>

        {/* <ModalLayout
          centered={false}
          show={showFAQform}
          handleClose={() => {
            setShowFAQform(false);
          }}
          title={`${menuState.formEditMode === true ? "Edit FAQ" : "Add FAQ"}`}
          closeIcon={<span className="material-symbols-outlined">close</span>}
        >
          <AddFAQForm
            setShowFAQform={setShowFAQform}
            refetch={refetch}
            hasUpdatePermission={hasUpdatePermission}
          />
        </ModalLayout> */}

        {/* <ModalLayout
          centered={false}
          show={showTableDeleteModal}
          handleClose={closeTableEditModal}
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete this item?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={closeTableEditModal}
              submitAction={ActionDelete}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout> */}
      </div>
    </>
  );
};

export default Invoice;

import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";

import useEmail from "./useEmail";
import CreateEmail from "./CreateEmail";

const Email = () => {
  const {
    state,
    showModal,
    emailData,
    currentPage,
    rolesState,
    paginationOptions,
    handlePagination,
    handlePageSize,
    refetch,
    isFetching,
    hasCreatePermission,
    hasEditPermission,
    hasDeletePermission,
    hasViewPermission,
    handleCreateClick,
    handleDelete,
    handleSort,
    handleEdit,
    getRow,
    closeModal,
  } = useEmail();

  return (
    <>
      {" "}
      <HeadingGroup
        title={"Email"}
        className={`pro-mb-4`}
        buttonTitle={hasCreatePermission === true ?? false ? "Create" : ""}
        handleClick={() => handleCreateClick()}
      />{" "}
      <div className={`col-auto pro-pt-3 pro-pb-6`}>
        {" "}
        <Table
          data={emailData?.data?.rows?.data || []}
          deletable={false}
          uniqueID={"id"}
          fields={emailData?.data?.field}
          // showCheckBox={hasEditPermission}
          showCheckBox={true}
          SortIcon={<FaSort />}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          handleEdit={handleEdit}
          loading={isFetching}
          clear={state.clearSelection}
          perpage={state?.currentPageSize}
          assignable={false}
          multiSelect={false}
        />{" "}
        {emailData?.data?.rows?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            defaultValue={paginationOptions?.filter(
              (item) => item.value === state?.currentPageSize
            )}
            totalPageCount={emailData?.data?.rows?.last_page}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
        <ModalLayout
          centered={false}
          show={state.showCreateModal}
          handleClose={() => closeModal()}
          title={state?.is_edit ? "Update Email" : "Create Email"}
          closeIcon={<span className="material-symbols-outlined">close</span>}
          backdrop="static"
        >
          <CreateEmail closeModal={() => closeModal()} refetch={refetch} />
        </ModalLayout>{" "}
      </div>{" "}
    </>
  );
};

export default Email;

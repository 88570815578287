import React from "react";
import useFilter from "./useFilter";
import Select from "react-select";
import { Button } from "@wac-ui-dashboard/wac_component_library";

const Filter = () => {
  const {
    formik,
    handleReset,
    statusData,
    streamData,
    courseData,
    streamObj,
    statusObj,
    courseObj,
    branchList,
    branchObj,
  } = useFilter();

  return (
    <div>
      {/* Render form fields for the "Basic" tab */}

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Course
        </label>
        <Select
          id="course_id"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          isMulti={false}
          name="course_id"
          options={courseData?.data}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          // value={courseData?.data?.item_types?.filter((m) =>
          //   formik.values.course_id?.includes?.(m.id)
          // )}
          value={courseObj}
          // onChange={(value) =>
          //   formik.setFieldValue(
          //     "course_id",
          //     value.map((v) => v.id)
          //   )
          // }
          onChange={(value) => formik.setFieldValue("course_id", value?.id)}
          menuPlacement="auto"
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Course Status
        </label>
        <Select
          id="course_status"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          isMulti={false}
          name="course_status"
          options={statusData}
          getOptionValue={(option) => option?.statusType}
          getOptionLabel={(option) => option?.title}
          // value={statusData?.filter((m) =>
          //   formik.values.course_status?.includes?.(m.statusType)
          // )}
          value={statusObj}
          // onChange={(value) =>
          //   formik.setFieldValue(
          //     "course_status",
          //     value.map((v) => v.statusType)
          //   )
          // }
          onChange={(value) =>
            formik.setFieldValue("course_status", value?.statusType)
          }
          menuPlacement="auto"
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Stream
        </label>
        <Select
          id="stream_id"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          isMulti={false}
          name="stream_id"
          options={streamData?.rows}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.name}
          // value={streamData?.data?.item_types?.filter((m) =>
          //   formik.values.stream_id?.includes?.(m.id)
          // )}
          value={streamObj}
          // onChange={(value) =>
          //   formik.setFieldValue(
          //     "stream_id",
          //     value.map((v) => v.id)
          //   )
          // }
          onChange={(value) => formik.setFieldValue("stream_id", value?.id)}
          menuPlacement="auto"
        />
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Branch
        </label>
        <Select
          id="branch_id"
          placeholder={"Select"}
          className={"pro-input lg"}
          classNamePrefix="pro-input"
          isMulti={false}
          name="branch_id"
          options={branchList}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.label}
          // value={branchData?.data?.item_types?.filter((m) =>
          //   formik.values.branch_id?.includes?.(m._id)
          // )}
          value={branchObj}
          // onChange={(value) =>
          //   formik.setFieldValue(
          //     "branch_id",
          //     value.map((v) => v._id)
          //   )
          // }
          onChange={(value) => formik.setFieldValue("branch_id", value?.value)}
          menuPlacement="auto"
        />
      </div>

      {/* <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Date Of Joining
        </label>
        <input
          className={`pro-input lg`}
          type="date"
          name="join_start_date"
          placeholder="Select Date"
          onChange={formik.handleChange}
          value={formik?.values?.join_start_date}
        />
      </div> */}

      <div
        className={`col-12 pro-d-flex pro-justify-end ${"offcanvas-footer-sticky-btn"}`}
      >
        <Button className={"pro-btn-link lg pro-px-5"} onClick={handleReset}>
          Reset
        </Button>

        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

export default Filter;
